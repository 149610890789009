reports.directive('changeYear', ['$uibModal', function ($uibModal) {
    return {
        restrict: 'E',
        scope: true,
        template: '<button class="btn btn-default" ng-click="changeYearModal()">Zmień</button>',
        link: function (scope, element, attrs) {
            scope.changeYearModal = function() {

                scope.processing = false;

                var modalCorrectionInstance = $uibModal.open({
                    templateUrl: appRoot + '/reports/views/modals/changeYear.modal.html',
                    size: 'small',
                    animation: true,
                    scope: scope,
                    resolve: {
                        items: function () {
                            return null;
                        }
                    }
                });

                modalCorrectionInstance.opened.then(function () {
                    scope.saveYear = function() {
                        scope.save(scope.data, true);
                    }
                    scope.cancel = function() {
                        modalCorrectionInstance.close();
                    }
                }, function () {
                    console.info('Modal dismissed at: ' + new Date());
                });
            }
        }
    }
}]);