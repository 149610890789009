/**
 * Created by jgolebiewski on 24.07.2015.
 */
/*
 * Szablon modułu
 */


var reports = angular.module("reports", [
    "ui.router", "roles"
]);

reports.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES', function ($stateProvider, $urlRouterProvider, USER_ROLES) {

    $urlRouterProvider.otherwise("/dashboard");

    $stateProvider
        .state('reports', {
            url: "/reports",
            abstract: true
        })

        .state('reports.list', {
            url: "",
            views: {
                '@': {
                    templateUrl: appRoot + "reports/views/reports.list.html",
                    controller: "ReportsListController"
                }
            },
            ncyBreadcrumb: {
                label: 'Sprawozdania'
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            }
        })
        .state('reports.list.company', {
            url: "/company/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "reports/views/company.reports.list.html",
                    controller: "CompanyReportsListController"
                }
            },
            ncyBreadcrumb: {
                label: 'Sprawozdania Podmiotu'
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            },
            resolve: {
                companyEntity: ['$stateParams', 'companyService', function ($stateParams, companyService) {
                    return companyService.getCompany($stateParams.id);
                }]
            }
        })

        .state('reports.new', {
            url: "/new",
            views: {
                '@': {
                    templateUrl: appRoot + "reports/views/report.html",
                    controller: "NewReportController"
                }
            },
            ncyBreadcrumb: {
                parent: 'reports.list',
                label: 'Sprawozdanie'
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            },
            resolve: {
                sections: ['companyResource', '$rootScope', function (companyResource, $rootScope) {
                    return companyResource.get({id: $rootScope.loggedUser.company.id, action: 'sections'}).$promise;
                }],
                reportEntity: function () {
                    return null;
                },
                companyProfile: ['companyService', function (companyService){
                    return companyService.getCompanyProfileForReport();
                }]
            }
        })

        .state('reports.edit', {
            url: "/edit/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "reports/views/report.html",
                    controller: "ReportController"
                }
            },
            ncyBreadcrumb: {
                parent: 'reports.list',
                label: 'Sprawozdanie'
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            },
            resolve: {
                reportEntity: ['reportsResource', '$stateParams', function (reportResource, $stateParams) {
                    return reportResource.get({id: $stateParams.id}).$promise.catch(function(error){
                        // FIX ME
                    });
                }],
            }
        })
        .state('reports.preview', {
            url: "/preview/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "reports/views/report.preview.html",
                    controller: "PreviewReportController"
                }
            },
            ncyBreadcrumb: {
                parent: 'reports.list',
                label: 'Podgląd sprawozdania'
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            },
            resolve: {
                reportEntity: ['reportsResource', '$stateParams', function (reportResource, $stateParams) {
                    return reportResource.get({id: $stateParams.id}).$promise.catch(function(error){
                        // FIX ME
                    });
                }]
            }
        })

}]);