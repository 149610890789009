users.controller('CompanyController', ['$scope', 'companyService','toaster','provincesService','profileDictionaryResource','companyEntity','VERSION',
    function ($scope, companyService, toaster, provincesService, profileDictionaryResource, companyEntity, VERSION) {

        var disabled = false;

        if ($scope.loggedUser.role.name == $scope.userRoles.su) {
            disabled = true;
        }

        $scope.VERSION = VERSION;
        $scope.isNipEditDisabled = true;
        $scope.disabled = disabled;
        $scope.data = companyEntity.data;
        $scope.dataProfile = {items: []};
        $scope.files = [];
        $scope.provinces = provincesService.getProvincesWithoutNames();
        $scope.loadingProfile = true;
        $scope.szwo = [];
        $scope.fgaz = [];

        $scope.save = save;
        $scope.updateProfile = updateProfile;


        getFiles();
        getCompanyProfile();

        $scope.loadHistoryEvent = function (){
            $scope.$broadcast("loadHistoryEvent", true);
        };

        $scope.closeCompanyEvent = function (){
            $scope.$broadcast("closeCompanyEvent", true);
        };

        profileDictionaryResource.get({}, function(response){
            $scope.loadingProfile = false;
            if (response.success == true) {
                $scope.szwo = response.data.slice(0,16);
                $scope.fgaz = response.data.slice(16, (response.data.length+1));
            }
        });



        function save() {
            companyService.update($scope.data.id, $scope.data).then(function(response){
                if (response.success == true) {
                    toaster.pop('success', 'Dane zaktualizowane');
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }



        function getFiles () {
            companyService.getCompanyFiles($scope.data.id).then(function (response) {
                if (response.success == true) {
                    $scope.files = response.data;
                }
            });
        }


        function getCompanyProfile() {
            companyService.getCompanyProfile($scope.data.id).then(function (response) {
                $scope.dataProfile.items = response;
            });
        }


        function updateProfile() {

            if ($scope.dataProfile.items == undefined || $scope.dataProfile.items.length == 0) {
                return toaster.pop('warning', "Wybierz profil działalności firmy");
            }

            companyService.updateProfile($scope.loggedUser.company.id, $scope.dataProfile.items).then(function(response){
                if (response.success == true) {
                    toaster.pop('success', 'Profil został uaktualniony');
                } else {
                    toaster.pop('error', response.error);
                }
            });

        }
    }]);
