users.controller('UsersListController', ['$scope', '$uibModal', 'usersService', 'usersGridService', 'toaster', '$q', 'usersResource', 'rolesResource',
    function ($scope, $uibModal, usersService, usersGridService, toaster, $q, usersResource, rolesResource) {

        $scope.listName = 'UsersList';

        $scope.showForm = {s: false};

        var newData = {
            firstName: null,
            surname: null,
            email: null,
            emailRepeat: null,
            status: 0
        };

        $scope.type = 'new';
        $scope.data = angular.copy(newData);

        usersGridService.scope = $scope;
        usersGridService.resource = usersResource;
        $scope.gridOptions = usersGridService.getGridOptions();


        rolesResource.get({}, function (response) {

            var roles = [];
            angular.forEach(response.data, function (role) {

                roles.push({
                    id: role.id,
                    label: role.name == 'ADMIN' ? 'Administrator' : 'Osoba kontaktowa'
                })

            });

            usersGridService.roles = roles;

            var index = 5;
            if ($scope.loggedUser.role.name != $scope.userRoles.su) {
                index = 3;
            }

            $scope.gridOptions.columnDefs[index].filter.options = roles;
        });


        usersGridService.getPage();

        $scope.intiForm = function (form) {
            $scope.userForm = form;
        };

        $scope.save = save;
        $scope.cancel = cancel;
        $scope.status = status;
        $scope.roles = roles;
        $scope.open = open;
        $scope.closeForm = closeForm;
        $scope.openForm = openForm;

        $scope.delete = function () {

        usersService.delete($scope.idToDelete).then(function () {
                $scope.idToDelete = null;
                usersGridService.getPage();
                $scope.modalInstance.close();
            });

        };

    function save () {
            $scope.saving = true;
            usersService.save($scope.data).then(function (response) {
                $scope.saving = false;
                $scope.showForm.s = false;
                if (response.success == true) {
                    toaster.pop('success', "Użytkownik został dodany");
                    usersGridService.getPage();
                    resetForm();
                } else {
                    toaster.pop('error', response.error);
                }
            });

        }


        function open(id) {

            $scope.idToDelete = id;
            $scope.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/common/views/modals/delete.modal.html',
                size: 'small',
                animation: true,
                scope: $scope,
                resolve: {
                    items: function () {
                        return $scope.items;
                    }
                }
            });
        }

    function cancel () {
            $scope.modalInstance.close();
            $scope.idToDelete = null;
        }


        function status(status) {
            var def = $q.defer();

            def.resolve([
                {id: 0, title: 'Aktywny'},
                {id: 1, title: 'Zablokowany'},
                {id: 3, title: 'Niepotwierdzony'},
                {id: 6, title: 'Zamknięty'}
            ]);
            return def;
        }

        function roles(status) {
            var def = $q.defer();

            def.resolve([
                {id: 2, title: 'Administrator'},
                {id: 3, title: 'Użytkownik'},
            ]);
            return def;
        }

        function closeForm() {
            $scope.showForm.s = false;
            resetForm();
        }

        function openForm() {
            $scope.showForm.s = true;
        }

        function resetForm() {
            $scope.data = angular.copy(newData);

            $scope.userForm.$setPristine(true);
            $scope.userForm.$setUntouched(true);
            $scope.userForm.$setSubmitted(true);
        }

    }]);