/**
 * Created by jgolebiewski on 24.07.2015.
 */

var auth = angular.module("auth", [
    "ui.router",
    'webStorageModule', 'common'
]);

auth.config(['$stateProvider', '$urlRouterProvider', '$compileProvider','$httpProvider', function ($stateProvider, $urlRouterProvider, $compileProvider, $httpProvider) {

    $urlRouterProvider.otherwise("/");

    $stateProvider
        .state('login', {
            url: "",
            templateUrl: appRoot + "authentication/views/login.html",
            controller: "LoginController"
        })

        .state('remind', {
            url: "/remind",
            templateUrl: appRoot + "authentication/views/remind.html",
            controller: "RemindController"
        })

        .state('contact', {
            url: "/contact",
            templateUrl: appRoot + "authentication/views/contact.html",
            controller: "ContactController"
        })

        .state('logout', {
            url: "/logout",
            templateUrl: appRoot + "authentication/views/logout.html",
            controller: "LogoutController",
            data: {
                authorizedRoles: []
            }
        });

    $compileProvider.debugInfoEnabled(false);
    $httpProvider.interceptors.push('LoaderInterceptor');
}]);