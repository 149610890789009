users.controller('AccountToDeleteController', ['$scope', 'userId', 'registerResource', 'registerEntity','toaster', 'messagesResource','$state','provincesService','companyResource','$http','companyService',
    function ($scope, userId, registerResource, registerEntity, toaster, messagesResource, $state, provincesService, companyResource, $http, companyService) {

        $scope.disabledForm = false;
        $scope.saving = false;
        $scope.editV = {
            s: false
        };

        $scope.group = 'single';

        $scope.toClose = true;

        $scope.provinces = provincesService.getProvincesWithoutNames();
        $scope.data = {
            documents: []
        };

        $scope.edit = edit;
        $scope.save = save;
        $scope.cancel = cancel;
        $scope.close = close;
        $scope.resetRequest = resetRequest;
        $scope.assign = assign;



        $scope.removeFilee = function (index, name) {
            $http.get('/files/delete/' + name).then(function(response){
                if (response.data.success == true) {
                    $scope.register.files.splice(index, 1);
                    toaster.pop('success', "Plik usunięty");
                } else {
                    toaster.pop('error', "Błąd usuwania pliku");
                }

            });
        };


        if ($scope.loggedUser.role.name == $scope.userRoles.su) {
            $scope.disabledForm = true;
        }


        if (registerEntity != null) {
            $scope.register = angular.copy(registerEntity.data);
        }

        $scope.url = '/companies/' + $scope.register.id + '/files';

        $scope.message = null;

        $scope.sendMessage = function () {

            if ($scope.message == null) {
                return toaster.pop('warning', "Wpisz wiadomość");
            }

            var data = {
                from: $scope.loggedUser,
                to: $scope.register.users[0],
                message: $scope.message,
                group: $scope.group
            };

            messagesResource.send(data, function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Wiadomość wysłana");
                    $scope.message = '';
                }
            });


        };


        function edit () {
            $scope.editV.s = true;
        }

        function save(data) {
            registerResource.update({id: $scope.register.id, action: 'update'}, data, function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Dane uaktualnione");
                    $scope.editV.s = false;
                    registerEntity.data = response.data;
                }
            });
        }


        function cancel () {
            $scope.register = angular.copy(registerEntity.data);
            $scope.editV.s = false;
        }


        function close () {
            companyResource.delete({id: $scope.register.id}, function (response){
                if (response.success == true) {
                    toaster.pop('success', "Konto zamknięte");
                    return $state.go('users.deletes');
                } else {
                    toaster.pop('error', response.error);
                }

            });
        }

        function resetRequest() {
            var data = angular.copy($scope.register);
            data.status = 1;
            registerResource.update({id: $scope.register.id, action: 'update'}, data, function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Prośba anulowana");
                    return $state.go('users.deletes');
                } else {
                    toaster.pop('error', response.error);
                }

            });
        }


        function assign() {
            companyService.assign($scope.register.id, 'close').then(function(response){
                $scope.register.responsible_close = $scope.loggedUser;
            });
        }

    }]);