/**
 * Created by jgolebiewski on 07.12.2015.
 */
reports.controller('ReportsListController', ['$scope', '$log', '$uibModal', 'reportsService', 'reportsGridService', 'reportsResource','$q', function ($scope, $log, $uibModal, reportsService, reportsGridService, reportsResource, $q) {

    var vm = this;

    vm.report = null;
    vm.modalAcceptInstance = null;

    $scope.listName = 'ReportsList';

    $scope.$log = $log;
    $scope.openDeleteModal = openDeleteModal;
    $scope.cancel = cancel;
    $scope.delete = deleteReport;
    $scope.copyReport = copyReport;
    $scope.status = status;
    $scope.openAcceptModal = openAcceptModal;
    $scope.openIncorrectModal = openIncorrectModal;
    $scope.confirm = confirm;
    $scope.markIncorrect = markIncorrect;
    $scope.saving = false;
    $scope.idToDelete = null;
    $scope.checkedReport = null;

    reportsGridService.scope = $scope;
    reportsGridService.resource = reportsResource;
    $scope.gridOptions = reportsGridService.getGridOptions();

    reportsGridService.getPage();

    function openDeleteModal(id) {
        $scope.idToDelete = id;
        $scope.modalInstance = $uibModal.open({
            templateUrl: appRoot + '/common/views/modals/delete.modal.html',
            size: 'small',
            animation: true,
            scope: $scope,
            resolve: {
                items: function () {
                    return null;
                }
            }
        });
    }

    function cancel() {
        if ($scope.modalInstance) {
            $scope.modalInstance.close();
            $scope.idToDelete = null;
        }

        if (vm.modalAcceptInstance) {
            vm.modalAcceptInstance.close();
            $scope.report = null;
        }

    }

    function deleteReport() {
        reportsService.deleteReport($scope.idToDelete).then(function (data) {
            cancel();
            reportsGridService.getPage();
        })
    }

    function copyReport(id) {

        reportsService.copyReport(id).then(function (data) {

            cancel();

            reportsGridService.getPage();
        })
    }

    $scope.exists = false;
    function openAcceptModal(report) {

        $scope.report = report;

        vm.modalAcceptInstance = $uibModal.open({
            templateUrl: appRoot + '/reports/views/modals/accept.modal.html',
            size: 'small',
            animation: true,
            scope: $scope,
            resolve: {
                items: function () {
                    return null;
                }
            }
        });

        vm.modalAcceptInstance.opened.then(function (selectedItem) {
            $scope.checking = true;
            reportsService.checkReportExists({year: $scope.report.reportYear, company: report.companyId}).then(function (data) {
                $scope.checkedReport = data;
                $scope.checking = false;
            })


        }, function () {
            console.info('Modal dismissed at: ' + new Date());
        });
    }

    function openIncorrectModal(report) {
        $scope.report = report;

        vm.modalAcceptInstance = $uibModal.open({
            templateUrl: appRoot + '/reports/views/modals/incorrect.modal.html',
            size: 'small',
            animation: true,
            scope: $scope,
            resolve: {
                items: function () {
                    return null;
                }
            }
        });
    }


    function confirm() {
        $scope.saving = true;
        reportsService.confirmReport($scope.report).then(function(data){
            $scope.saving = false;
            cancel();
            reportsGridService.getPage();
        });
    }

    function markIncorrect() {
        $scope.saving = true;
        reportsService.markIncorrectReport($scope.report).then(function(data){
            $scope.saving = false;
            cancel();
            reportsGridService.getPage();
        });
    }

    $scope.toggleFilters = function() {
        if ($scope.showFilters) {
            $scope.showFilters = false;
        } else {
            $scope.showFilters = true;
        }
    }
}]);

reports.filter('roundUp', function () {
    return function (value) {
        return Math.ceil(value);
    };
});
