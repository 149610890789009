/**
 * Created by jgolebiewski on 07.12.2015.
 */

users.factory('reportsResource', ['$resource', function ($resource) {
    return $resource('/reports/:id/:action/:subId', {id: '@id', action: '@action', subId: '@subId'}, {
        update: {
            method: 'PUT'
        },
        show: {
            params: {
                'action': 'show'
            }
        },
        verify: {
            url: '/reports/verify/',
            method: 'POST'
        },
        correctionMessage: {
            url: '/reports/correction-message',
            method: 'POST'
        },
        checkCorrectionMessage: {
            url: '/reports/check-correction-message',
            method: 'GET'
        },
        preview: {
            url: '/reports/preview',
            method: 'POST'
        },
        prevstock: {
            url: '/reports/prevstock',
            method: 'POST'
        },
        hasSubmitedReport: {
            method: 'GET',
            url: '/reports/:id/hasSubmitedReport',
            params: {
                id: '@id'
            }
        },
        hasIncorrectReport: {
            method: 'GET',
            url: '/reports/:id/hasIncorrectReport',
            params: {
                id: '@id'
            }
        },
        messages: {
            method: 'GET',
            url: '/reports/:id/messages',
            params: {
                id: '@id'
            }
        }
    });
}]);