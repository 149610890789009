dictionaries.controller('DictionariesListController', ['$scope', 'dictionariesResource', 'dictionariesService', 'toaster', '$uibModal', 'reportsService', 'VERSIONS',
    function ($scope, dictionariesResource, dictionariesService, toaster, $uibModal, reportsService, VERSIONS) {
    	var self = this;

    	$scope.listName = 'DictionariesList';

	    dictionariesService.scope = $scope;
	    dictionariesService.resource = dictionariesResource;
	    $scope.gridOptions = dictionariesService.getGridOptions();
	    dictionariesService.getPage();

	    $scope.versions = VERSIONS;

	    $scope.show = { form: false };

	    $scope.data = {
	        type: 'SZWO',
	        version: 'v1',
	        components: []
	    };

	    $scope.defaultData = $scope.data;

	    $scope.showForm = function (status) {
	        $scope.show.form = status;
	    };

	    $scope.saving = false;
	    $scope.save = function () {
	        $scope.saving = true;
	        if ($scope.data.id == null) {
		        dictionariesResource.save($scope.data, function (response) {
		            $scope.saving = false;
		            if (response.success) {
		                $scope.show = { form: false };
		                toaster.pop('success', 'Pozycja dodana');
		                $scope.data = $scope.defaultData;
		            } else {
		                toaster.pop('error', response.error);
		            }
		        });
	        } else {
	        	dictionariesResource.update({ id: $scope.data.id }, $scope.data, function (response) {
	                $scope.saving = false;
	                if (response.success) {
	                	$scope.show = { form: false };
	                    toaster.pop('success', 'Dane uaktualnione');
	                    $scope.data = $scope.defaultData;
	                } else {
	                    toaster.pop('error', response.error);
	                }
	            });
	        }
	    };

	    $scope.idToDelete = null;
	    $scope.open = function (id) {
	        $scope.idToDelete = id;
	        $scope.modalInstance = $uibModal.open({
	            templateUrl: appRoot + '/common/views/modals/delete.modal.html',
	            size: 'small',
	            animation: true,
	            scope: $scope,
	            resolve: {
	                items: function () {
	                    return $scope.items;
	                }
	            }
	        });
	    };

	    $scope.delete = function () {
	        dictionariesResource.delete({ id: $scope.idToDelete }, function (response) {
	            if (response.success) {
	                toaster.pop('success', 'Pozycja została usunięta');
	                $scope.idToDelete = null;
	                dictionariesService.getPage();
	            } else {
	                toaster.pop('error', response.error);
	            }
	            $scope.modalInstance.close();
	        });
	    };

	    $scope.setData = function (data) {
	    	$scope.data = data;
	    };

	    $scope.chemicals = [];
	    $scope.searchChemicals = function (searchTxt) {
	        if (searchTxt.length == 0) {
	        	if ($scope.data.chemical && $scope.chemicals.length == 0) {
	        		searchTxt = $scope.data.ashrae;
	        	} else {
	        		return false;
	        	}
	        }
	        reportsService.getChemicals(searchTxt, 'q').then(function (data) {
	            $scope.chemicals = data;
	        });
	    };

	    $scope.clearChemical = function ($event, $select) {
			$event.stopPropagation();
			$select.selected = null;
		}

	    $scope.addComponent = function () {
	        $scope.data.components.push({
	            chemical: {},
				share: null,
	        });
	    };

	    $scope.removeComponent = function ($index) {
	        $scope.data.components.splice($index, 1);
	    };
	}
]);
