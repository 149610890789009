/**
 * Created by jgolebiewski on 05.08.2016
 */

(function () {

    messages.service('messagesService', messagesService);

    messagesService.$inject = ['messagesResource', 'toaster'];

    function messagesService(messagesResource, toaster) {

        var vm = this;

        vm.messagesFilters = {
            page: 1,
            limit: 20
        };

        vm.usersFilters = {
            page: 1,
            limit: 20
        };

        var service = {
            getMessages: getMessages,
            getSendersList: getSendersList,
            sendMessage: sendMessage
        };

        return service;


        function checkResponse(response) {
            if (response.success == true) {
                return response.data;
            } else {
                console.warn(response);
                return false;
            }
        }

        function checkMResponse(response) {
            if (response.success == true) {
                return response;
            } else {
                console.warn(response);
                return false;
            }
        }


        function getMessages(queryParams) {
            
            if (queryParams.page == undefined) {
                queryParams.page = vm.messagesFilters.page;
            }

            if (queryParams.limit == undefined) {
                queryParams.limit = vm.messagesFilters.limit;
            }


            return messagesResource.get(queryParams).$promise.then(checkMResponse);
        }

        function getSendersList(queryParams) {

            if (queryParams.page == undefined) {
                queryParams.page = vm.usersFilters.page;
            }

            if (queryParams.limit == undefined) {
                queryParams.limit = vm.usersFilters.limit;
            }

            return messagesResource.getSendersList(queryParams).$promise.then(checkMResponse);
        }
        
        function sendMessage (message) {
            return messagesResource.save(message).$promise.then(sendInfo);
        }
        
        function sendInfo (response) {
            if (response.success == true) {
                toaster.pop('success', 'Wiadomość wysłana');
                return response.data;
            } else {
                console.warn("RESPONSE: ", response);
                toaster.pop('error', 'Nie udało się wysłać wiadomości');
                return false;
            }
        }
    }

})();