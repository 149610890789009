(function () {

    'use strict';

    angular.module('news').controller('NewsListController', NewsListController);

    NewsListController.$inject = ['$scope', 'newsService', '$uibModal'];

    function NewsListController($scope, newsService, $uibModal) {
        var vm = this;
        vm.itemToDelete = null;
        vm.list = null;

        $scope.links = [];
        $scope.documents = [];
        $scope.news = [];

        $scope.pagination = {
            links: {
                pageSize: 10,
                total: 0,
                currentPage: 1
            },
            documents: {
                pageSize: 10,
                total: 0,
                currentPage: 1
            },
            news: {
                pageSize: 3,
                total: 0,
                currentPage: 1
            }
        };

        $scope.pageChanged = pageChanged;
        $scope.deleteItem = deleteItem;
        $scope.showDeleteModal = showDeleteModal;
        $scope.hide = hide;

        getLinks();
        getDocuments();
        getNews();



        function getLinks() {

            var params = {
                limit: $scope.pagination.links.pageSize,
                page: $scope.pagination.links.currentPage
            };

            newsService.getLinks(params).then(function(response){
                $scope.links = response.data;
                $scope.pagination.links.total = response.total;
            });
        }

        function getDocuments() {

            var params = {
                limit: $scope.pagination.documents.pageSize,
                page: $scope.pagination.documents.currentPage
            };

            newsService.getDocuments(params).then(function(response){
                $scope.documents = response.data;
                $scope.pagination.documents.total = response.total;
            });
        }

        function getNews() {
            var params = {
                limit: $scope.pagination.news.pageSize,
                page: $scope.pagination.news.currentPage
            };
            newsService.getNews(params).then(function(response){
                $scope.news = response.data;
                $scope.pagination.news.total = response.total;
            });
        }


        function getList(list) {
            if (list == 'news') {
                getNews();
            }

            if (list == 'documents') {
                getDocuments();
            }

            if (list == 'links') {
                getLinks();
            }
        }


        function pageChanged(currentPage, list) {
            $scope.pagination[list].currentPage = currentPage;
            getList(list);
        }


        function deleteItem() {
            return newsService.deleteItem(vm.itemToDelete.id).then(function (data) {
                if (data !== false) {
                    getList(vm.listToReload);
                    hide();
                }
            })
        }


        function showDeleteModal(itemToDelete, list) {
            vm.itemToDelete = itemToDelete;
            vm.listToReload = list;
            $scope.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/news/views/delete.modal.html',
                // size: 'od-lg',
                animation: true,
                backdrop: 'static',
                keyboard: false,
                scope: $scope
            });
        }

        function hide() {
            vm.listToReload = null;
            vm.itemToDelete = null;
            $scope.modalInstance.dismiss('cancel');
        }
    }

})();