users.service('usersGridService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'LOGGED_USER', 'USER_ROLES','usersResource','temporaryAdminResource','companyResource','toaster',
    function ($http, uiGridConstants, moment, gridService, LOGGED_USER, USER_ROLES, usersResource,temporaryAdminResource, companyResource, toaster) {
        var self = this;

        self.resource = null;

        self.gridApi = null;

        self.scope = null;

        self.resourceParams = {};

        var dateRangePickerOptions = {
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Wybierz",
                "cancelLabel": "Anuluj",
                "fromLabel": "Od",
                "toLabel": "Do",
                "customRangeLabel": "Zdefiniuj"
            },
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
                'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
                'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
                'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        var gridColumns = [
            {
                name: "Podmiot",
                width: '*',
                minWidth: 200,
                field: "name",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents" data-ng-if="row.entity.company.firstName == null"><a href="#/users/company/{{ row.entity.companyId }}" >{{ row.entity.company.name }}</a></div>' +
                '<div class="ui-grid-cell-contents" data-ng-if="row.entity.company.name != null"><a href="#/users/company/{{ row.entity.companyId }}" >{{ row.entity.company.name }}</a></div>'
            },
            {
                name: "NIP",
                width: '*',
                minWidth: 150,
                field: "nip",
                fieldType: 'text'
            },
            {
                name: 'Imię i nazwisko',
                width: '*',
                field: "users.surname",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.firstName }} {{ row.entity.surname }}</div>'
            },
            {
                name: 'E-mail',
                width: '*',
                minWidth: 200,
                field: "email",
                fieldType: 'text',
            },
            {
                name: 'Telefon',
                width: '*',
                field: "phone",
                fieldType: 'text',
            },
            {
                name: 'Typ konta',
                width: '*',
                field: 'roleId',
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents" data-ng-if="row.entity.roleId == 2">Administrator</div><div class="ui-grid-cell-contents" data-ng-if="row.entity.roleId == 3">Osoba kontaktowa</div>',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
                '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    options: {}
                }
            },
            {
                name: "Status",
                width: '10%',
                //minWidth: 150,
                enableFiltering: true,
                enableSorting: false,
                enableColumnMenu: false,
                fieldType: 'text',
                field: 'users.status',
                cellTemplate: function () {
                    return '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 0"><div class="label label-success">Aktywny</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 1"><div class="label label-danger">Zablokowany</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 3"><div class="label label-default">Niepotwierdzony</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 6"><div class="label label-default">Usunięty</div></div>'
                },

                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
                '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    //term: 0,
                    options: [{id: 0, label: 'Aktywny'}, {id: 1, label: 'Zablokowany'}, {id: 3, label: 'Niepotwierdzony'}, {id: 6, label: 'Usunięty'}]
                }
            },

        ];


        var columnForSU = {
            name: "Edycja",
            fieldType: 'text',
            width: '12%',
            //minWidth: 150,
            enableFiltering: false,
            enableSorting: false,
            enableColumnMenu: false,
            cellTemplate: '<div class="ui-grid-cell-contents">' +
            '' +
            '<a href="#/users/{{ row.entity.id }}" class="btn btn-primary btn-xs"><i class="fa fa-edit"></i> <span class="hidden-sm hidden-xs">Edytuj</span></a>' +
            '</div>'
        };

        var columnForNotSU = {
            name: "Edycja",
            fieldType: 'text',
            width: '12%',
            //minWidth: 150,
            enableFiltering: false,
            enableSorting: false,
            enableColumnMenu: false,
            cellTemplate: '<div class="ui-grid-cell-contents">' +
            '' +
            '<button type="button"  data-ng-click="grid.appScope.open(row.entity.id)" class="btn btn-warning btn-xs"><i class="fa fa-trash"></i> <span class="hidden-sm hidden-xs">Usuń</span></button>&nbsp;' +
            '<a href="#/users/{{ row.entity.id }}" class="btn btn-primary btn-xs"><i class="fa fa-edit"></i> <span class="hidden-sm hidden-xs">Edytuj</span></a>' +
            '</div>'
        };



        self.getGridOptions = function () {
            gridService.query.type = 0;
            gridService.resource = self.resource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = self.getGridColumns();
            return gridOptions;
        };

        self.getGridColumns = function () {
            if ((LOGGED_USER.role.name != USER_ROLES.su || gridService.resourceParams.action == 'users') && gridColumns[0].name == 'Podmiot') {
                gridColumns.splice(0,1);
            }

            if ((LOGGED_USER.role.name != USER_ROLES.su || gridService.resourceParams.action == 'users') && gridColumns[0].name == 'NIP') {
                gridColumns.splice(0,1);
            }

            if (LOGGED_USER.role.name != USER_ROLES.su) {
                gridColumns.push(columnForNotSU);
            } else {
                gridColumns.push(columnForSU);
            }



            return gridColumns;

        };

        self.getDateRangePickerOptions = function () {
            return dateRangePickerOptions;
        };

        self.getPage = function () {
            return gridService.getPage();
        };

        self.setNewAdmin = function (userId) {
            return usersResource.update({id: userId, action: 'activate'}).$promise;
        };

        self.save = function (data) {
            return usersResource.save(data).$promise;
        };

        self.update = function (id, data) {
            return usersResource.update({id: id}, data).$promise;
        };

        self.getTemporaryAdmin = function(currentAdminId) {
            return temporaryAdminResource.get({subId: currentAdminId, action: 'getByAdmin'}).$promise.then(manageResponse);
        };

		self.getCurrentAdmin = function(companyId) {
            return companyResource.get({id: companyId, action: 'getAdmin'}).$promise.then(manageResponse);
        };

        self.getFiles = function (id) {
            return usersResource.get({id: id}, {action: 'files'}).$promise;
        };


        self.updateTemporaryAdmin = function (data) {
            return temporaryAdminResource.update({id: data.id}, data).$promise.then(showResponseInfo)
        };

        self.denyNewAdmin = function (id, tempAdmin) {
            return usersResource.update({id: id, action: 'deny'}, {tempAdmin: tempAdmin != null ? tempAdmin.id : null}).$promise;
        };

        self.markFirstLogin = function () {
            return usersResource.update({id: LOGGED_USER.id, action: 'firstLogin'}).$promise;
        };

		self.markDisclaimer = function () {    			
			window.location.assign("/users/"+LOGGED_USER.id+"/disclaimer");			
        };
		
        self.searchUser = function (txt) {
            return usersResource.get(txt).$promise.then(manageResponse);
        };

        return self;

        function manageResponse(response) {
            if (response.success == true) {
                return response.data;
            } else {
                return null;
            }
        }

        function showResponseInfo(response) {
            if (response.success == true) {
                toaster.pop('success', 'Dane uaktualnione');
                return response.data;
            } else {
                toaster.pop('error', 'Bład aktualizacji');
                console.warn(response);
            }
        }
		
		function markFirstLogin() {
            //return companyResource.save({id: companyId, action: 'closeRequest'}, data).$promise.then(showResponseInfo);
        }
		
		
    }]);