
var newsModule = angular.module("news", [
    "ui.router","roles", 'angularUtils.directives.dirPagination'
]);

newsModule.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES', function ($stateProvider, $urlRouterProvider, USER_ROLES) {

    $urlRouterProvider.otherwise("/");

    $stateProvider
        .state('news', {
            url: "/news",
            templateUrl: appRoot + "news/views/news.html",
            controller: "NewsListController",
            ncyBreadcrumb: {
                label: 'Ogłoszenia'
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            }
        })

        .state('news.new', {
            url: "/add/{type:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "news/views/news.item.html",
                    controller: "NewsController",
                }
            },
            ncyBreadcrumb: {
                label: 'Ogłoszenia'
            },
            resolve: {
                newsEntity: function () {
                    return null;
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })

        .state('news.edit', {
            url: "/edit/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "news/views/news.item.html",
                    controller: "NewsController",
                }
            },
            ncyBreadcrumb: {
                label: 'Ogłoszenia'
            },
            resolve: {
                newsEntity: ['$stateParams','newsService', function ($stateParams, newsService) {
                    return newsService.get($stateParams.id);
                }]
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })

        .state('news.view', {
            url: "/view/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "news/views/news.view.html",
                    controller: "NewsController"
                }
            },
            ncyBreadcrumb: {
                label: 'Ogłoszenia'
            },
            resolve: {
                newsEntity: ['$stateParams','newsService', function ($stateParams, newsService) {
                    return newsService.get($stateParams.id);
                }]
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            }
        })



}]);