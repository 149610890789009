App.directive('onlyAdmin', ['USER_ROLES', '$rootScope', function(USER_ROLES, $rootScope) {
    return {
        restrict: 'A',
        link: function(scope, element) {
            if ($rootScope.loggedUser.role.name != USER_ROLES.admin) {
                $(element).remove();
            }
        }
    }
}]);
