/**
 * Created by jgolebiewski on 03.12.2015.
 */
angular.module('App')
    .filter('trustedHtml', ['$sce', function ($sce) {
        return function (text) {
            return $sce.trustAsHtml(text);
        };
    }])
    .filter('propsFilter', function () {
        return function (items, props) {
            var out = [];

            if (angular.isArray(items)) {
                var keys = Object.keys(props);

                items.forEach(function (item) {
                    var itemMatches = false;

                    for (var i = 0; i < keys.length; i++) {
                        var prop = keys[i];
                        var text = props[prop].toLowerCase();
                        if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                            itemMatches = true;
                            break;
                        }
                    }

                    if (itemMatches) {
                        out.push(item);
                    }
                });
            } else {
                // Let the output be the input untouched
                out = items;
            }

            return out;
        };
    })
    .filter('nameFilter', function () {
        return function (input, defaultUser) {
            if (input == undefined) {
                return defaultUser != undefined ? defaultUser : '';
            }

            if (input.hasOwnProperty('firstName') && input.hasOwnProperty('surname')) {
                return input.firstName + ' ' + input.surname;
            }

            return input;
        }
    })
    .filter('dateFromString', ['$filter', function ($filter) {
        return function (input, format) {
            if (input == undefined) {
                return ''
            }

            if (format == undefined) {
                format = 'MM/dd/yyyy';
            }
            return $filter('date')(new Date(input), format);
        }
    }])
    .filter('newsStatusFilter', function () {
        return function (input) {
            return input == 0 ? 'Nie opublikowwany' : 'Opublikowany';
        }
    });
