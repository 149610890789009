reports.directive('maxPage', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var maxPage = attrs.max;

            element.context.addEventListener('keyup', function(e){
                // update model value if input value is more then max page value
                if (this.valueAsNumber > maxPage) {
                    scope.params.page(maxPage);
                }
            });
        }
    }
});