App.service('gridService', ['i18nService', 'toaster', '$timeout', 'localStorageService', function (i18nService, toaster, $timeout, localStorageService) {
    var self = this;
    //i18nService.setCurrentLang('pl');
    self.query = {
        sort: {},
        filters: [],
        pageNumber: 1,
        pageSize: 10
    };

    self.resource = null;

    self.gridApi = null;

    self.scope = null;

    self.resourceParams = {};

    self.paginationOptions = {
        pageNumber: 1,
        pageSize: self.query.pageSize,
        sort: null
    };

    self.dataLoaded = false;


    self.gridOptions = {
        enableFiltering: true,
        flatEntityAccess: false,
        showGridFooter: false,
        fastWatch: true,
        enableGridMenu: true,
        enableColumnMenus: false,
        paginationPageSizes: [10, 50, 75],
        paginationPageSize: self.paginationOptions.pageSize,
        enablePaginationControls: false,
        enableMinHeightCheck: false,
        rowHeight: 60,
        minimumColumnSize: 50,
        useExternalFiltering: true,
        useExternalPagination: true,
        useExternalSorting: true,
        enableCellEditOnFocus: true,
        data: []
    };


    var prepareQuery = function (queryObj) {
        var string = {
            page: queryObj.pageNumber,
            limit: queryObj.pageSize
        };

        if (typeof queryObj.customFilters != 'undefined') {
            angular.forEach(Object.keys(queryObj.customFilters), function(item) {
                string[item] = queryObj.customFilters[item];
            });
        }

        if (queryObj.type != undefined) {
            string.type = queryObj.type;
        }

        string['sort[field]'] = queryObj.sort.field;
        string['sort[direction]'] = queryObj.sort.direction;

        angular.forEach(queryObj.filters, function (filter, i) {
            string['filters[' + i + '][field]'] = filter.field;
            if (angular.isObject(filter.search)) {
                string['filters[' + i + '][search][from]'] = filter.search.from;
                string['filters[' + i + '][search][to]'] = filter.search.to;
            } else {
                string['filters[' + i + '][search]'] = filter.search;
            }

            if (!self.dataLoaded) {
                angular.forEach(self.gridOptions.columnDefs, function (column, c) {
                    if (column.field == filter.field && !column.filter) {
                        if (!column.filters) {
                            self.gridOptions.columnDefs[c].filters = [{}];
                        }
                        if (!angular.isObject(filter.search)) {
                            self.gridOptions.columnDefs[c].filters[0].term = filter.search;
                        } else {
                            self.gridOptions.columnDefs[c].filters[0].term = {
                                startDate: new Date(filter.search.from),
                                endDate: new Date(filter.search.to)
                            };
                        }
                    }
                    if (column.field == filter.field && column.filter) {
                        if (!column.filters) {
                            self.gridOptions.columnDefs[c].filters = [column.filter];
                        }
                        self.gridOptions.columnDefs[c].filters[0].term = filter.search;
                    }
                });
            }
        });

        return string;
    };


    self.gridOptions.onRegisterApi = function (gridApi) {
        self.scope.gridApi = gridApi;

        gridApi.pagination.on.paginationChanged(self.scope, function (newPage, pageSize) {
            self.query.pageNumber = newPage;
            self.query.pageSize = pageSize;
            self.getPage();
        });

        gridApi.core.on.filterChanged(self.scope, function () {

            //remote filtering
            var grid = this.grid;

            var filters = [];
            var applyFilters = false;
            angular.forEach(grid.columns, function (column) {

                var term = column.filters[0].term;


                if (term != undefined && angular.isDefined(column.colDef.fieldType) && column.colDef.fieldType == 'dateRange' && term.startDate != '' && term.startDate != null) {
                    term = {
                        from: term.startDate.format('YYYY-MM-DD'),
                        to: term.endDate.format('YYYY-MM-DD')
                    };
                    applyFilters = true;
                }

                if (term != undefined && (term.length > 0 || term.from != undefined || angular.isNumber(term))) {
                    applyFilters = true;
                    filters.push({
                        field: column.field,
                        search: term
                    })
                }


            });

            $timeout(function () {
                if (applyFilters == true && filters.length > 0) {
                    self.query.filters = filters;

                    localStorageService.set((self.scope.listName || 'list') + '_filters', angular.toJson(self.query.filters));

                    self.getPage();
                }

                if (applyFilters == false && filters.length == 0 && self.query.filters.length > 0) {
                    self.query.filters = [];

                    localStorageService.set((self.scope.listName || 'list') + '_filters', angular.toJson(self.query.filters));

                    self.getPage();
                }
            }, 800);


        });

        if (gridApi.edit != undefined) {

            gridApi.edit.on.afterCellEdit(self.scope, function (rowEntity, colDef, newValue, oldValue) {
                if (newValue == oldValue) {
                    return false;
                }

                self.resource.update({id: rowEntity.id}, rowEntity, function (response) {
                    if (response.success == true) {
                        toaster.pop('success', 'Dane uaktualnione')
                    } else {
                        toaster.pop('error', response.error);
                    }
                });

                self.scope.$apply();
            });

        }

        gridApi.core.on.sortChanged(self.scope, function (grid, sortColumns) {
            if (sortColumns.length > 0) {
                self.query.sort = {
                    field: sortColumns[0].field,
                    direction: sortColumns[0].sort.direction
                };
            }

            localStorageService.set((self.scope.listName || 'list') + '_sort', angular.toJson(self.query.sort));

            self.getPage();
        });

        gridApi.changeLimit = function (size) {
            self.scope.size = size;
            self.query.pageSize = self.scope.size;
            self.gridOptions.paginationPageSize = self.scope.size;
            self.getPage();
        };

        gridApi.updateCustomFilters = function(customFilters) {
            self.query.customFilters = customFilters;
            localStorageService.set((self.scope.listName || 'list') + '_customFilters', angular.toJson(self.query.customFilters));
            self.getPage();
        }

    };


    self.getPage = function () {
        self.scope.loadingData = true;

        if (!self.dataLoaded) {
            var filters = localStorageService.get((self.scope.listName || 'list') + '_filters');
            if (filters !== null) {
                self.query.filters = angular.fromJson(filters);
            }

            var sort = localStorageService.get((self.scope.listName || 'list') + '_sort');
            if (sort !== null) {
                self.query.sort = angular.fromJson(sort);
            }

            var customFilters = localStorageService.get((self.scope.listName || 'list') + '_customFilters');
            if (customFilters !== null) {
                self.query.customFilters = angular.fromJson(customFilters);
                self.scope.customFilters = self.query.customFilters;
            }
        }

        return self.resource.get(prepareQuery(self.query), self.resourceParams, function (response) {
            if (response.success == true) {
                self.scope.loadingData = false;
                self.scope.gridOptions.totalItems = response.total;
                self.scope.gridOptions.data = response.data;
                self.dataLoaded = true;
            }
        })
    };

    self.getPaginationOptions = function () {
        return self.paginationOptions;
    };

    self.getGridOptions = function () {
        self.scope.size = self.query.pageSize;

        self.scope.pageSizes = [10, 20, 50];

        self.gridOptions.data = [];

        if (typeof self.query.customFilters != 'undefined') {
            self.query.customFilters = {};
        }

        if (self.query.filters.length != 0) {
            self.query.filters = [];
        }

        if (self.query.sort.length != 0) {
            self.query.sort = [];
        }

        if (self.query.type != undefined) {
            delete self.query.type;
        }

        self.dataLoaded = false;

        return self.gridOptions;
    };

    return self;

}]);