/**
 * Created by jgolebiewski on 19.10.2015.
 */
reports.factory('chemicalsResource', ['$resource', function ($resource) {
    return $resource('/chemicals/:id/:action/:subId', {id: '@id', action: '@action', subId: '@subId'}, {
        update: {
            method: 'PUT'
        },
        search: {
            method: 'GET',
            params : {
                action: 'find'
            }
        }
    });
}]);