/**
 * Created by jgolebiewski on 08.08.2016.
 */

(function () {


    angular.module('common')
        .filter('customPrecisionFilter', ['$filter', function ($filter) {
            return function (input, usePrimaryValue) {

                if (input == undefined) {
                    return '';
                }

                var valueField = 'value';
                if (usePrimaryValue) {
                    valueField = 'primaryValue';
                }
                if (input.hasOwnProperty('zastLab')) {
                    return $filter('number')(input[valueField], input.zastLab);
                }

                return $filter('number')(input[valueField], 1);
            };
        }]);


})();