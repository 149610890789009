/**
 * Created by mpiasecki on 12.12.2016. 
 */

users.factory('copyResource', ['$resource', function ($resource) {
    return $resource('/reports/copy/:id', {id: '@id'}, {
        copy: {
            method: 'GET'
        },
        
    });
}]);