users.service('companyService', ['profileDictionaryResource', 'companyResource','NgTableParams','toaster','LOGGED_USER',
    function (profileDictionaryResource, companyResource, NgTableParams, toaster, LOGGED_USER) {

        var vm = this;

        vm.page = 1;
        vm.count = 10;
        vm.defaultSorting = {'users.created_at' : 'desc'};
        vm.filter = {};


        var companyService = {
            getCities: getCities,
            getCompanies: getCompanies,
            getCompany: getCompany,
            getCompanySections: getCompanySections,
            getCompanyProfile: getCompanyProfile,
            getCompanyFiles: getCompanyFiles,
            updateProfile: updateProfile,
            update: update,
            getNgTableParams: getNgTableParams,
            sendCloseRequest: sendCloseRequest,
            assign: assign,
            getCompanyProfileForReport: getCompanyProfileForReport,
            hasConfirmedReport: hasConfirmedReport
        };

        return companyService;

        function getCities(searchObj) {
            return companyResource.searchCities(searchObj).$promise;
        }

        function getCompanies(searchObj) {
            return companyResource.search(searchObj).$promise;
        }

        function getCompanySections(id, year) {
            return companyResource.get({id: id, action: 'sections', year: year}).$promise;
          }

        function getCompanyProfile(id) {
          return companyResource.get({id: id, action: 'profile'}).$promise.then(processData);
        }

        function getCompanyProfileForReport(year) {
            var  id = LOGGED_USER.company.id;
            return companyResource.get({id: id, action: 'profile', year: year}).$promise;
        }


        function processData (response) {

            if (response.success == true) {

                var items = [];
                angular.forEach(response.data, function (item){
                    items.push(item.profilesDictionary_id);
                });
                return items;
            } else {
                console.warn("Bład pobierania danych", response);
            }

        }

        function getCompanyFiles(id) {
          return companyResource.get({id: id, action: 'files'}).$promise;
        }


        function updateProfile (id, data) {
            return companyResource.save({id: id, action: "profile"}, data).$promise;
        }


        function update(id, data) {
            return companyResource.update({id: id}, data).$promise;
        }

        function getNgTableParams(companyId, defParams) {

            var reqParams = defParams == undefined ? {action: 'users', id: companyId} : defParams;

            return new NgTableParams({
                    page: vm.page,
                    count: vm.count,
                    sorting: vm.defaultSorting,
                    filter: vm.filter
                },
                {
                    total: 0, // length of data
                    getData: function ($defer, params) {

                        vm.page = params.page();
                        vm.count = params.count();
                        vm.defaultSorting = params.sorting();
                        vm.filter = params.filter();

                        return companyResource.get(params.url(),reqParams).$promise.then(function (data) {
                            params.total(data.total);
                            return data.data;
                        });
                    }
                });
        }

        function getCompany(companyId) {
            return companyResource.get({id: companyId}).$promise;
        }

        function sendCloseRequest(companyId, data) {
            return companyResource.save({id: companyId, action: 'closeRequest'}, data).$promise.then(showResponseInfo);
        }

        function showResponseInfo(response) {
            if (response.success == true) {
                toaster.pop('success', 'Dane uaktualnione');
                return response.data;
            } else {
                toaster.pop('error', 'Bład aktualizacji');
                console.warn(response);
            }
        }

        function assign (companyId, caseToAssign) {
            if (caseToAssign == null || caseToAssign == undefined) {
                return false;
            }

            return companyResource.assign({id: companyId}, {caseToAssign: caseToAssign}).$promise.then(showAssignInfo);
        }

        function showAssignInfo(response) {
            if (response.success == true) {
                toaster.pop('success', "Zostałeś przypisany");
                return response.data;
            } else {
                toaster.pop('error', response.error);
                console.warn(response);
            }
        }

        function hasConfirmedReport(companyId, year) {
            return companyResource.hasConfirmedReport({id: companyId, year: year}).$promise;
        }
    }]);