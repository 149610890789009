users.controller('RegisterEditController', ['$scope', 'userId', 'registerResource', 'registerEntity','toaster', 'messagesResource','$state','provincesService','companyResource','$http','companyService', 'MessagesTemplatesService',
    function ($scope, userId, registerResource, registerEntity, toaster, messagesResource, $state, provincesService, companyResource, $http, companyService, MessagesTemplatesService) {

		const TEMPLATE_CATEGORY_ID = 2;

        $scope.disabledForm = false;
        $scope.saving = false;
        $scope.editV = {
            s: false
        };

        $scope.group = 'register';

        $scope.provinces = provincesService.getProvincesWithoutNames();
        $scope.data = {
            documents: []
        };

        $scope.edit = edit;
        $scope.save = save;
        $scope.cancel = cancel;
        $scope.sendWelcomeMessage = sendWelcomeMessage;
        $scope.reject = reject;
        $scope.sendActivateMessage = sendActivateMessage;
        $scope.assign = assign;



        $scope.removeFilee = function (index, name) {
            $http.get('/files/delete/' + name).then(function(response){
                if (response.data.success == true) {
                    $scope.register.files.splice(index, 1);
                    toaster.pop('success', "Plik usunięty");
                } else {
                    toaster.pop('error', "Błąd usuwania pliku");
                }

            });
        };


        if ($scope.loggedUser.role.name == $scope.userRoles.su) {
            $scope.disabledForm = true;
        }


        if (registerEntity != null) {
            $scope.register = angular.copy(registerEntity.data);
        }

        $scope.url = '/companies/' + $scope.register.id + '/files';

        $scope.message = null;

        $scope.activate = function () {

            registerResource.update({id: $scope.register.id, action: 'activate'}, function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Konto aktywowane");
                    return $state.go('users.registers');
                }
            });

        };

        $scope.sendMessage = function () {

            if ($scope.message == null) {
                return toaster.pop('warning', "Wpisz wiadomość");
            }

            var data = {
                from: $scope.loggedUser,
                to: $scope.register.users[0],
                message: $scope.message,
                group: $scope.group
            };

            messagesResource.send(data, function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Wiadomość wysłana");
                    $scope.message = '';
                    $scope.data.template = null;
                }
            });


        };


        function edit () {
            $scope.editV.s = true;
        }

        function save(data) {
            registerResource.update({id: $scope.register.id, action: 'update'}, data, function (response) {
            	$scope.saving = false;
                if (response.success == true) {
                    toaster.pop('success', "Dane uaktualnione");
                    $scope.editV.s = false;
                    registerEntity.data = response.data;

                    if (response.data.remember_token_url) {
                    	$scope.register.remember_token_url = response.data.remember_token_url;
                    }
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }


        function cancel () {
            $scope.register = angular.copy(registerEntity.data);
            $scope.editV.s = false;
        }

        function sendWelcomeMessage() {
            registerResource.get({id: $scope.register.users[0].id, action: 'sendMessage'}, function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Wiadomość wysłana");
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }



        function reject () {

            registerResource.update({id: $scope.register.id, action: 'reject'}, function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Rejestracja odrzucona");
                    return $state.go('users.registers');
                }
            });

        }

        function sendActivateMessage() {
            registerResource.get({id: $scope.register.users[0].id, action: 'sendActivateMessage'}, function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Wiadomość wysłana");
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }

        function assign() {
            companyService.assign($scope.register.id, 'registration').then(function(response){
                $scope.register.responsible_registration = $scope.loggedUser;
            });
        }

        $scope.templates = [];
        $scope.templatesLoading = false;
        $scope.searchTemplates = function (searchTxt) {
            $scope.templatesLoading = true;
            MessagesTemplatesService.getTemplates(searchTxt, TEMPLATE_CATEGORY_ID).then(function (response) {
                if (response.success == true) {
                    $scope.templates = response.data;
                    $scope.templatesLoading = false;
                }
            });
        };
        $scope.searchTemplates('');

        $scope.setBodyTemplate = function (selectedItem) {
            $scope.message = selectedItem.body;
        };
    }]);