/**
 * Created by jgolebiewski on 3.11.2015.
 */
users.factory('rolesResource', ['$resource', function ($resource) {
    var service = $resource('/roles');

    var roles = [
            { id: 1, name: 'SU', fullname: 'Admin Wewnętrzny' },
            { id: 2, name: 'ADMIN', fullname: 'Admin Zewnętrzny'},
            { id: 3, name: 'USER', fullname: 'Użytkownik Zewnętrzny'}
    ];

    service.getRoles = function() {
        return roles;
    }

    service.getSuTypes = function(callback, suType) {
        if (suType) {
            return $resource('/roles/sutypes', {type: suType}).get({}, callback);
        }
        return $resource('/roles/sutypes').get({}, callback);
    }

    service.getRoleByName = function(name) {
        return roles.find(function(role) {
            return role.name == name;
        });
    }
    
    service.get = function($obj, callback) {
        return $resource('/roles').get($obj, callback);
    }

    return service;
}]);