/**
 * Created by jgolebiewski on 05.08.2016.
 */

(function () {
    messages.controller('MessagesController', messagesController);

    messagesController.$inject = ['$scope', 'messagesService', '$anchorScroll', 'usersService', 'MessagesTemplatesService', '$timeout', '$stateParams', 'localStorageService'];

    function messagesController ($scope, messagesService, $anchorScroll, usersService, MessagesTemplatesService, $timeout, $stateParams, localStorageService) {
        var vm = this;

        $scope.newMessageForm = {
            show: false
        };

        $scope.showTime = false;

        $scope.summerNoteOptions.height = 100;
        $scope.usersList = [];
        $scope.messages = [];
        $scope.filters = {};
        $scope.loadingUsers = false;
        $scope.loadingMessages = false;
        $scope.sendingMessage = false;
        $scope.activeUser = null;
        $scope.disabledLoadUsersButton = false;

        $scope.search = {
            field: 'user'
        };
        if (typeof $stateParams.type !== 'undefined') {
            $scope.search.type = $stateParams.type;
        }

        $scope.newAnswer = {
            sender: $scope.loggedUser,
            text: null
        };

        $scope.pageSize = 10;
        $scope.currentPage = 1;
        $scope.total = 0;

        $scope.sending = false;
        $scope.searching = false;

        $scope.users = [];

        $scope.data = {
            receivers: [],
            sender: $scope.loggedUser
        };
        $scope.data.group = '';

        $scope.groupNames = {
        	single: '',
        	multiple: '',
        	all: 'Wszyscy',
        	group_incorrect: 'Sprawozdania błędne',
        	group_zero: 'Sprawozdania zerowe',
        	group_in: 'Sprawozdania przywozu',
        	group_out: 'Sprawozdania wywozu',
        	group_usage: 'Sprawozdania danego zastosowania',
			group_incorrect_without_correct: 'Sprawozdania błędne bez korekty sprawozdań w terminie 14 dni',
			register: 'Rejestracja konta',
        	change_admin: 'Zmiana administratora',
        };

        $scope.groups = [
        	{ code: '', name: 'Wyszukaj odbiorców' },
        	{ code: 'all', name: $scope.groupNames.all },
        	{ code: 'group_incorrect', name: $scope.groupNames.group_incorrect },
        	{ code: 'group_zero', name: $scope.groupNames.group_zero },
        	{ code: 'group_in', name: $scope.groupNames.group_in },
        	{ code: 'group_out', name: $scope.groupNames.group_out },
            { code: 'group_usage', name: $scope.groupNames.group_usage },
            { code: 'group_incorrect_without_correct', name: $scope.groupNames.group_incorrect_without_correct }
        ];

        $scope.filterGroups = [
			{ code: '', name: $scope.groupNames.all },
			{ code: 'group_incorrect', name: $scope.groupNames.group_incorrect },
        	{ code: 'group_zero', name: $scope.groupNames.group_zero },
        	{ code: 'group_in', name: $scope.groupNames.group_in },
        	{ code: 'group_out', name: $scope.groupNames.group_out },
        	{ code: 'group_usage', name: $scope.groupNames.group_usage },
			{ code: 'register', name: $scope.groupNames.register },
    		{ code: 'change_admin', name: $scope.groupNames.change_admin },
        ];

        $scope.usages = [
            { name: 'Produkcja', value: 'produkcja'},
            { name: 'Instalacja', value: 'instalacja'},
            { name: 'Serwisowanie', value: 'serwisowanie'},
            { name: 'Inne - Zastosowanie jako substrat w procesach chemicznych', value: 'substrat-w-proc-chemicznych'},
            { name: 'Inne - Zastosowanie laboratoryjne lub analityczne', value: 'laboratoryjne-lub-analityczne'},
            { name: 'Inne', value: 'inne'},
        ]

        $scope.years = getYears();

        vm.totalUsersList = 0;

        if ($scope.filters.limit == undefined) {
            $scope.filters.limit = 10;
        }

        if ($scope.filters.page == undefined) {
            $scope.filters.page = 1;
        }


        $scope.getMessagesForUser = getMessagesForUser;
        $scope.sendNewAnswer = sendNewAnswer;
        $scope.pageChanged = pageChanged;
        $scope.loadNextUsers = loadNextUsers;
        $scope.searchUsers = searchUsers;
        $scope.refresh = refresh;
        $scope.refreshMessages = refreshMessages;

        $scope.cancelForm = cancelForm;
        $scope.searchUser = searchUser;
        $scope.send = send;
        $scope.setYear = setYear;

        searchUsers();

        /**
         * Pobieranie listy korespondencyjnej
         * @param filters
         * @param firstLoad
         */
        function getUsersList(filters, firstLoad) {
            $scope.loadingUsers = true;

            if (firstLoad != undefined && firstLoad == true) {
                $scope.usersList = [];
            }

            messagesService.getSendersList(filters).then(function (data){
                if (data != false) {
                    $scope.loadingUsers = false;

                    if ($scope.usersList.length == 0) {
                        $scope.usersList = data.data;
                    } else {
                        $scope.usersList = $scope.usersList.concat(data.data);
                    }


                    vm.totalUsersList = data.total;


                    if (vm.totalUsersList ==  $scope.usersList.length) {
                        $scope.disabledLoadUsersButton = true;
                    }

                    if (firstLoad != undefined && firstLoad == true) {

                        $scope.activeUser = data.data[0];
                        getMessagesForUser($scope.activeUser, true);
                    }
                }
            });
        }

        function loadNextUsers() {
            $scope.filters.page += 1;
            getUsersList($scope.filters, false);
        }


        /**
         * Filtrowanie listy nadawcow/odbiorcow
         */
        function searchUsers() {

            $scope.filters.page = 1;
            angular.extend($scope.filters, $scope.search);

            getUsersList($scope.filters, true);

        }

        /**
         * pobieranie listy wiadomosci dla wybranego uzytkownika
         * @param user
         * @param clearMessages
         * @returns {boolean}
         */
        function getMessagesForUser(user, clearMessages) {

            if (user == undefined) {
                $scope.messages = [];
                return false;
            }

            var queryParams = {
                page: $scope.currentPage,
                limit:  $scope.pageSize
            };


            if ($scope.loggedUser.role.name == $scope.userRoles.su) {
                queryParams.user = user.id;

                if (user.type) {
                	queryParams.type = user.type;
                	queryParams.receiver = queryParams.user;
                	delete queryParams.user;
                }
                else if ($scope.search.type) {
                	queryParams.type = $scope.search.type;
                }
            }

            $scope.loadingMessages = true;
            $scope.activeUser = user;


            if (clearMessages == undefined || clearMessages == true) {
                $scope.messages = [];
            }


            messagesService.getMessages(queryParams).then(function (data) {
                if (data != false) {
                    $scope.messages = data.data;
                    $scope.loadingMessages = false;
                    $scope.total = data.total;
                    $anchorScroll();
                    localStorageService.set('totalMessages', null);
                }
            })
        }

        /**
         * wyslanie odpowiedzi w watku
         */
        function sendNewAnswer() {
            $scope.sendingMessage = true;
            $scope.newAnswer.receivers = [$scope.activeUser];
            messagesService.sendMessage($scope.newAnswer).then(function(data){
                $scope.sendingMessage = false;
                $scope.showTime = false;
                if (data != false) {
                    $scope.newAnswer.id = data.id;
                    $scope.newAnswer.date = data.date.date;

                    $scope.messages.unshift($scope.newAnswer);
                    $scope.newAnswer = {
                        sender: $scope.loggedUser,
                        text: null
                    };

                    cancelForm();
                    getUsersList($scope.filters, true);
                }
            });
        }

        function pageChanged(newPage) {
            $scope.currentPage = newPage;
            getMessagesForUser($scope.activeUser);
        }

        function refresh() {
            getUsersList($scope.filters, true);
        }

        function refreshMessages() {
            getMessagesForUser($scope.activeUser, false);
        }


        function cancelForm() {
            $scope.data.receivers = [];
            $scope.data.text = '';
            $scope.data.group = '';
            $scope.data.reportYear = '';
            $scope.data.usage = '';
            $scope.data.template = null;

            $scope.newMessageForm = {
                show: false
            };
        }

        /**
         * wyszukiwanie odbiorcow wiadomosci przy tworzeniu nowej wiadomosci
         * @param $search
         * @returns {boolean}
         */
        function searchUser($search) {

            if ($search == undefined || $search == '') {
                return false;
            }

            var data = {
                // filters: [{field: 'users.surname','search': $search}]
                //'filters[0][field]': 'users.surname',
            	'filters[0][field]': 'extName',
                'filters[0][search]': $search,
                limit:100
            };
            $scope.searching = true;
            usersService.searchUser(data).then(function (data) {
                if (data != null) {
                    $scope.users = data;
                    $scope.searching = false;
                }
            })
        }

        /**
         * wyslanie nowej wiadomosci
         */
        function send() {
            $scope.sending = true;
            messagesService.sendMessage($scope.data).then(function (data){
                $scope.sending = false;
                if (data != null) {
                    cancelForm();
                    getUsersList($scope.filters, true);
                }
            });
        }

        function getYears() {
            var years = [];
            var currentYear = new Date().getFullYear();
            currentYear--;
            for (var i = currentYear; i >= currentYear - 1; i--) {
                years.push(i.toString());
            }

            return years;
        }

        function setYear(selected) {
        	if (!$scope.data.reportYear && selected.code && selected.code != 'all') {
        		$scope.data.reportYear = new Date().getFullYear() - 1;
        	}
        	if ($scope.data.reportYear && (!selected.code || selected.code == 'all')) {
        		$scope.data.reportYear = '';
        	}
        }

        $scope.parameters = function(parameters) {
            return Object.values(JSON.parse(parameters)).filter(function(val) {
                return val !== '' && val !== null;
            }).join(', ');
        }

        $scope.templates = [];
        $scope.templatesLoading = false;
        $scope.searchTemplates = function (searchTxt) {
            $scope.templatesLoading = true;
            MessagesTemplatesService.getTemplates(searchTxt, undefined, true).then(function (response) {
                if (response.success == true) {
                    $scope.templates = response.data;
                    $scope.templatesLoading = false;
                }
            });
        };
        $scope.searchTemplates('');

        $scope.setBodyTemplate = function (selectedItem) {
            $scope.data.text = selectedItem.body;
        };
    }

})();