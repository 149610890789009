/**
 * Created by jgolebiewski on 07.12.2015.
 */
reports.controller('NewReportController', ['companyService', 'profileDictionaryResource', '$uibModal', '$scope', 'sections', 'reportsService', 'reportEntity', 'moment', '$state', 'companyProfile','$timeout', 'toaster',
    function (companyService, profileDictionaryResource, $uibModal, $scope, sections, reportsService, reportEntity, moment, $state, companyProfile, $timeout, toaster) {

        var self = this;

        self.isEdit = false;
        $scope.valuePrecision = 3;
        $scope.onePrecision = 1;
        $scope.countPrecision = 0;
        $scope.save = save;
        $scope.sendReport = sendReport;
        $scope.addSubstance = addSubstance;
        $scope.setActive = setActive;
        $scope.manageMixture = manageMixture;
        $scope.addSubstanceToMixture = addSubstanceToMixture;
        $scope.searchChemicals = searchChemicals;
        $scope.setSubstanceName = setSubstanceName;
        $scope.removeMixture = removeMixture;
        $scope.countMixture = countMixture;
        $scope.searchMixtures = searchMixtures;
        $scope.addProduct = addProduct;
        $scope.removeProduct = removeProduct;
        $scope.showSubSection = showSubSection;
        $scope.getCountiesList = getCountiesList;
        $scope.checkProduct = checkProduct;
        $scope.setMixture = setMixture;
        $scope.searchChemicalsForMixture = searchChemicalsForMixture;
        $scope.setMode = setMode;
        $scope.showMixtureSelection = showMixtureSelection;
        $scope.checkRequired = checkRequired;
        //$scope.checkRequired3 = checkRequired3;
        $scope.countProduction = countProduction;
        $scope.serviceInstalation = reportsService.serviceInstalation;
        $scope.inneProcesyField = reportsService.inneProcesy;
        $scope.checkIsSomethingFilled = checkIsSomethingFilled;
        $scope.markChecked = markChecked;
        $scope.checkIsSomethingFilledOdzysk = checkIsSomethingFilledOdzysk;
        $scope.verify = verify;
        $scope.calculateMaxRecycling = calculateMaxRecycling;
        $scope.checkMaxRecycling = checkMaxRecycling;

        $scope.nic = [];
        $scope.dataProfile = {items: []};
        $scope.szwo = [];
        $scope.fgc = [];
        $scope.productTypes = [];
        $scope.isCollapsed = true;
        $scope.activeTop = 'report';
        $scope.substanceMenu = [];
        $scope.activeSub = null;
        $scope.activeSection = null;
        $scope.activeSectionLink = 'report';
        $scope.chemicals = [];
        $scope.chemicalsForMix = [];
        $scope.mixturesList = [];
        $scope.countriesUE = [];
        $scope.countriesNonUE = [];
        $scope.chemicalsLoading = false;
        $scope.mixturesLoading = false;
        $scope.tmp = [{mixtureCount: 0}];
        $scope.loadingContent = true;
        $scope.productFields = {};
        $scope.showMixtureForm = {};

        $scope.years = reportsService.getYears();
        $scope.isEditMode = true;
        $scope.wymaganaProdukcja = reportsService.checkCompanyProfile(companyProfile.data, null, [4, 11]);
        $scope.wymaganaInstalacja = reportsService.checkCompanyProfile(companyProfile.data, 'doInstalacji');
        $scope.inneProcesy = reportsService.checkCompanyProfile(companyProfile.data, null, [3, 10]);
        $scope.produkcjaInneProcesy = reportsService.checkCompanyProfile(companyProfile.data, null, [3, 10]);
        $scope.odzysk = reportsService.checkCompanyProfile(companyProfile.data, 'Odzysk');
        $scope.przywozacyLubWywozacy = reportsService.checkCompanyProfile(companyProfile.data, null, [1,2,8,9]);
        $scope.przywozacyLubWywozacyProdukty = reportsService.checkCompanyProfile(companyProfile.data, null, [6,7,13,14]);

        $scope.sendingForm = false;
        $scope.sectionFormName = '';
        $scope.dataIndex = 0;
        $scope.emptyChemicalSearch = false;

        $scope.prevStock = [];

        self.emptyDataObject = {};
        self.defaultSettings = {
            szwofgc: {
                SZWO_FGC: reportsService.checkSZWOFGC(companyProfile.data),
                czyMieszanina: 0,
                czyPierwotna: 0,
                chemical: null,
                mixture: []
            }
        };


        var mixtureTemplate = {chemical: '', id: null};

        $scope.sections = sections.data;


        getProductTypes();
        getCountries();
        getCompanyProfile();
        getDictionaryProfiles();

        $scope.menuItems = [
            {
                index: null,
                menuIndex: 0,
                name: 'Część I',
                reportItemId: null,
                sectionLink: 'report',
                submenu: []
            },
            {
                index: 0,
                menuIndex: 1,
                reportItemId: null,
                name: 'Substancja 1',
                sectionLink: 'sub1',
                submenu: $scope.sections
            }

        ];

        prepareData();


        if (reportEntity != null) {

            if (reportEntity.data.status != 1) {
                toaster.pop('error', 'Nie można edytować sprawozdania - nie jest już w statusie "Szkic"');
                return $state.go('reports.list');
            }


            $scope.data = angular.copy(reportEntity.data);
            fillData();
            self.isEdit = true;
            prepareMenuItems(false);
            countMixtures();
            //countProduction();
        } else {
            $scope.data = {
                name: null,
                companyName: $scope.loggedUser.company.name,
                firstName: $scope.loggedUser.firstName,
                lastName: $scope.loggedUser.surname,
                creatorId: $scope.loggedUser.id,
                report_items: []
            };
            pushEmptyItem();
        }

        $scope.sections.unshift({id: 0, name: '1. SZWO - FGC', sectionName: 'szwofgc'});


        $scope.init = function (form) {
            $scope.reportForm = form;
        };


        $scope.$on('$includeContentLoaded', function (event) {
            $scope.loadingContent = false;
        });


        $scope.openDeleteModal = function (item) {

            $scope.idToDelete = item;
            $scope.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/common/views/modals/delete.modal.html',
                size: 'small',
                animation: true,
                scope: $scope,
                resolve: {
                    items: function () {
                        return $scope.items;
                    }
                }
            });
        };


        $scope.delete = function () {
            $scope.menuItems.splice($scope.idToDelete.menuIndex, 1);
            $scope.data.report_items.splice($scope.idToDelete.index, 1);

            if ($scope.idToDelete.reportItemId != undefined) {
                reportsService.deleteSubstance($scope.idToDelete.reportItemId);
            }

            $scope.cancel();
            prepareMenuItems(true);
            setActive(null, {
                index: null,
                menuIndex: 0,
                name: 'Część I',
                reportItemId: null,
                sectionLink: 'report',
                submenu: []
            }, 0);
        };

        $scope.cancel = function () {
            $scope.modalInstance.close();
            $scope.idToDelete = null;
        };


        function getProductTypes() {
            reportsService.getProductTypes().then(function (data) {
                $scope.productTypes = data;
            });
        }


        function searchChemicals($search, field) {

            if ($search.search.length == 0 || !$search) {
                return false;
            }

            $scope.chemicalsLoading = true;


            var type = $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].SZWO_FGC == 0 ? 'SZWO' : 'FGC';

            if ($scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].czyMieszanina == 1) {
                type = 'MIX';
            }


            reportsService.getChemicals($search.search, field, type).then(function (data) {
                $scope.chemicals = data;
                $scope.chemicalsLoading = false;
                $scope.emptyChemicalSearch = data.length == 0;

            });
        }

        function searchChemicalsForMixture($search) {

            if ($search.search.length == 0 || !$search) {
                return false;
            }

            $scope.chemicalsLoading = true;

            reportsService.getChemicals($search.search, 'q').then(function (data) {
                $scope.chemicalsLoading = false;
                $scope.chemicalsForMix = data;
                $scope.emptyChemicalSearch = data.length == 0;
            });
        }


        function searchMixtures($search) {

            if ($search.search.length == 0 || !$search) {
                return false;
            }

            $scope.mixturesLoading = true;
            reportsService.getChemicals($search.search, 'q', 'MIX').then(function (data) {
                $scope.mixturesLoading = false;
                $scope.mixturesList = data;
            });
        }


        function save(formData) {
            //console.log(formData);
            $scope.sendingForm = true;
            if (formData.name == null) {
                formData.name = 'Sprawozdanie ' + moment().format('DD/MM/YYYY HH:mm') + ' (szkic)';
            }
            reportsService.saveReport(formData).then(function (data) {
                $scope.sendingForm = false;
                if (formData.id == undefined) {
                    return $state.go('reports.edit', {id: data.id});
                }

                $scope.data = data;
                reportEntity.data = angular.copy(data);
                fillData();
            });
        }


        function setActive(subMenu, parent, index) {
            $scope.substanceMenu = [];
            $scope.activeSectionLink = parent.sectionLink;
            if (subMenu != null) {
                $scope.activeSub = 's' + subMenu.id + parent.sectionLink;
                $scope.activeSectionLink = subMenu.sectionName;
                $scope.activeSection = $scope.sections[index];
            }

            $scope.activeTop = parent.sectionLink;
            $scope.dataIndex = parent.index;

            $scope.substanceMenu[parent.index] = {
                activeSectionLink: $scope.activeSectionLink,
                activeSection: $scope.activeSection,
                activeTop: $scope.activeTop
            };


            if ($scope.substanceMenu[parent.index].activeSectionLink == 'szwofgc') {
                clearChemicalsList();
                countMixture();
                showMixtureSelection();
            }
        }


        function addSubstance() {
            var count = $scope.menuItems.length;
            var index = $scope.menuItems.push({
                index: count - 1,
                menuIndex: count,
                name: 'Substancja ' + count,
                sectionLink: 'sub' + count,
                submenu: $scope.sections
            });

            //$scope.substanceMenu[count-1] = {};
            pushEmptyItem();
        }

        function pushEmptyItem() {
          var emptySubstance = angular.copy(self.emptyDataObject);
          emptySubstance.maxRecycling = 0;
          emptySubstance.maxRecyclingError = false;
          $scope.data.report_items.push(emptySubstance);
        }

        function clearChemicalsList() {
            $scope.chemicals = [];
            $scope.chemicalsForMix = [];
            $scope.mixturesList = [];
        }

        function clearSelectedChemicals() {
            clearChemicalsList();

            if ($scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture != undefined) {
                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture = [];
            }

            if ($scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].chemical != undefined) {
                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].chemical = null;
            }

            if ($scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixtureName != undefined) {
                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixtureName = null;
            }


            if (self.isEdit == true &&
                reportEntity.data.report_items[$scope.dataIndex] != undefined &&
                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].czyMieszanina == reportEntity.data.report_items[$scope.dataIndex][$scope.activeSectionLink].czyMieszanina
            ) {

                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture = angular.copy(reportEntity.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture);
                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixtureName = angular.copy(reportEntity.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixtureName);

                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].chemical = angular.copy(reportEntity.data.report_items[$scope.dataIndex][$scope.activeSectionLink].chemical);
                setSubstanceName($scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].chemical);

            } else {
                setSubstanceName();
            }
            countMixture();
        }


        function manageMixture() {
            clearSelectedChemicals();

            if ($scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].czyMieszanina == 1) {
                addSubstanceToMixture();
            }
        }


        function addSubstanceToMixture() {
            if ($scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture == undefined) {
                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture = [];
            }

            if ($scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture.length == 0) {
                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture.push(angular.copy(mixtureTemplate));
            }

            $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture.push(angular.copy(mixtureTemplate));

        }


        function setSubstanceName(item, fromMixutre) {
            if (typeof item === 'undefined' || !item) {
                $scope.menuItems[$scope.dataIndex + 1].name = 'Substancja lub mieszanina ' + ($scope.dataIndex + 1);
            } else {
                $scope.menuItems[$scope.dataIndex + 1].name = item.name;
            }
            if (typeof item !== 'undefined' && item && typeof fromMixture === 'undefined') {
                $scope.data.report_items[$scope.dataIndex].gwp = Number(item.gwp);
            }
            if ($scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].czyMieszanina == 1) {
                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixtureName = item;
                if (typeof fromMixutre === 'undefined' || fromMixutre == true) {
                    setMixture(item);
                }

            }

            $scope.prevStock[$scope.dataIndex] = false;
            reportsService.prevstock($scope.data).then(function(response) {
    			var item = response.data.report_items[$scope.dataIndex];
                if (response.success && response.success[$scope.dataIndex] && item.zapasy && item.zapasy.ilosc1) {
                    $scope.data.report_items[$scope.dataIndex].zapasy.ilosc1 = item.zapasy.ilosc1;
                    $scope.prevStock[$scope.dataIndex] = true;
    			}
            });
        }

        function removeMixture($index) {
            $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture.splice($index, 1);
            countMixture();
        }

        function countMixture() {
            var count = 0;
            angular.forEach($scope.data.report_items[$scope.dataIndex]['szwofgc'].mixture, function (item) {
                if (item.share != undefined) {
                    count += parseFloat(item.share);
                }
            });

            if ($scope.tmp[$scope.dataIndex] == undefined) {
                $scope.tmp[$scope.dataIndex] = {};
            }

            if ($scope.tmp[$scope.dataIndex].mixtureCount == undefined) {
                $scope.tmp[$scope.dataIndex].mixtureCount = 0;
            }

            $scope.tmp[$scope.dataIndex].mixtureCount = Math.round(count * 100) / 100;
        }

        function countMixtures() {
            angular.forEach($scope.data.report_items, function (item, index) {
                var count = 0;

                angular.forEach(item['szwofgc'].mixture, function (mix) {
                    if (mix.share != undefined) {
                        //console.log(mix.share);
                        count += parseFloat(mix.share);
                    }
                });

                //console.log($scope.tmp);
                if ($scope.tmp[index] == undefined) {
                    $scope.tmp[index] = {};
                }
                $scope.tmp[index].mixtureCount = Math.round(count * 100) / 100;

            });

        }

        function countProduction() {
            angular.forEach($scope.data.report_items, function (item, index) {
                var count = 0;
                $scope.nic[index] = {};
                if (item['zastosowanie'] != undefined && item['zastosowanie']['produkcja'] != undefined) {
                    //angular.forEach(item['zastosowanie']['produkcja'].products, function (product) {
                    //    if (product.liczbSztuk != undefined) {
                    //        //console.log(mix.share);
                    //        count += parseFloat(product.liczbSztuk) * parseFloat(product.iloscWsadu);
                    //    }
                    //    if (product.masa != undefined) {
                    //        //console.log(mix.share);
                    //        count += parseFloat(product.masa) * parseFloat(product.iloscWsadu);
                    //    }
                    //});
                    //console.log(count);
                    $scope.nic[index].istotnego = null;
                }

            });

        }

        function setMixture($selected) {
            if ($selected == undefined) {
                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture = [];

            } else {
                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].mixture = $selected.components;
            }
            $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink].chemical = $selected;
            setSubstanceName($selected, false);
            showMixtureSelection(true);
            countMixture();
            clearCustomMixtureName();
        }

        function prepareData() {

            var tmp = self.defaultSettings;

            angular.forEach($scope.sections, function (section) {
                tmp[section.sectionName] = {};
                tmp[section.sectionName]['sectionId'] = section.id;
                angular.forEach(section.section_fields, function (subSection) {
                    tmp[section.sectionName][subSection.sectionFieldName] = {};

                });
            });
            self.emptyDataObject = tmp;

        }

        function fillData() {
            angular.forEach($scope.sections, function (section) {
                angular.forEach($scope.data.report_items, function (reportItem) {
                    if (reportItem[section.sectionName] == undefined) {
                        reportItem[section.sectionName] = {};
                    }
                    if (reportItem[section.sectionName]['sectionId'] == undefined) {
                        reportItem[section.sectionName]['sectionId'] = section.id;
                    }
                    angular.forEach(section.section_fields, function (subSection) {
                        if (reportItem[section.sectionName][subSection.sectionFieldName] == undefined) {
                            reportItem[section.sectionName][subSection.sectionFieldName] = {};
                        } else {

                            if (reportsService.przywozWywoz(subSection.sectionFieldName)) {
                                reportItem[section.sectionName][subSection.sectionFieldName].value = Number(reportItem[section.sectionName][subSection.sectionFieldName].value);
                            }
                        }
                    });

                });
            });
        }

        function addProduct(subSectionName) {
            if ($scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink][subSectionName].products == undefined) {
                $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink][subSectionName].products = []
            }

            var product = {
                product: '',
                liczbSztuk: null,
                masa: null,
                iloscWsadu: null,
                countries: []
            };

            var $index = $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink][subSectionName].products.push(product);

        }

        function removeProduct($index, subSectionName) {
            $scope.data.report_items[$scope.dataIndex][$scope.activeSectionLink][subSectionName].products.splice($index, 1);
            countProduction();
        }

        function showSubSection(subSection) {
            return ['obrot', 'odzysk', 'zapasy'].indexOf(subSection) != -1;
        }

        function prepareMenuItems(deletedItem) {


            if (deletedItem == false) {
                $scope.menuItems.splice(1, 1);//bo edycja
            } else {
                var tmp = $scope.menuItems.shift();
                $scope.menuItems = [];
                $scope.menuItems.push(tmp);
            }


            angular.forEach($scope.data.report_items, function (substance, index) {
                $scope.menuItems.push({
                    index: index,
                    menuIndex: index + 1,
                    reportItemId: substance.id,
                    name: setEditedSubstanceName(substance, index),
                    sectionLink: 'sub' + index,
                    submenu: $scope.sections

                })
            });

        }

        function setEditedSubstanceName(substance, index) {

            var name = "Substancja " + (index + 1);

            if (substance.szwofgc.chemical != undefined) {
                name = substance.szwofgc.chemical.name;
            } else if (substance.szwofgc.customMixtureName != null) {
                name = substance.szwofgc.customMixtureName;
            }


            return name;
        }

        function sendReport() {
            $scope.data.status = 2;
            $scope.sendingForm = true;
            reportsService.sendReport($scope.data).then(function (data) {

                $scope.sendingForm = false;
                return $state.go('reports.preview', {id: data.id});
            });
        }

        function getCountries() {
            reportsService.getCountries('ue').then(function (data) {
                $scope.countriesUE = data;
            });

            reportsService.getCountries('nonUE').then(function (data) {
                $scope.countriesNonUE = data;
            });

        }

        function getCountiesList(subSectionName) {
            if (subSectionName == 'wywozdoue' || subSectionName == 'przywozzue') {
                return $scope.countriesUE;
            }

            if (subSectionName == 'wywuzniedoue' || subSectionName == 'przywozniezue') {
                return $scope.countriesNonUE;
            }

        }

        function checkProduct(product, $index, sec) {
            if ($scope.productFields[sec] == undefined) {
                $scope.productFields[sec] = {};
            }

            $scope.productFields[sec][$index] = {
                masa: false,
                liczbSztuk: false
            };

            if (product.unit == 1) {
                $scope.productFields[sec][$index].masa = true;
            }

            if (product.unit == 0) {
                $scope.productFields[sec][$index].liczbSztuk = true;
            }

            if (product.unit == 2) {
                $scope.productFields[sec][$index].liczbSztuk = true;
                $scope.productFields[sec][$index].masa = true;
            }
            //console.log($scope.productFields);
        }


        function setMode(mode) {
            $scope.isEditMode = mode;

            if (mode === false) {
                verify($scope.data);
            }
        }

        function verify(formData) {
            reportsService.verifyReport(formData).then(function (data) {
                $scope.sendingForm = false;
                toaster.pop(data.status, 'Wynik weryfikacji: ' + data.message);
                $scope.verifyResult = data;
            });
        }

        function showMixtureSelection(fromButton) {

            if (fromButton != undefined && fromButton == true) {
                $scope.showMixtureForm[$scope.dataIndex] = true;
            }

            //if ($scope.data.report_items[$scope.dataIndex]['szwofgc'].customMixtureName != undefined) {
            $scope.showMixtureForm[$scope.dataIndex] = true;
            //}

            //console.log($scope.showMixtureForm);
        }


        function clearCustomMixtureName() {
            if ($scope.data.report_items[$scope.dataIndex]['szwofgc'].customMixtureName != undefined) {
                $scope.data.report_items[$scope.dataIndex]['szwofgc'].customMixtureName = null;
            }
        }


        //function checkRequired(sectionName, subSectionName, substanceIndex) {
        //    if (['przywoz', 'wywoz', 'zapasy'].indexOf(sectionName) == -1) {
        //        return;
        //    }
        //
        //    if (sectionName == 'zapasy') {
        //        return true;
        //    }
        //
        //    if (sectionName == 'przywoz') {
        //        var substance = $scope.data.report_items[substanceIndex];
        //        if (checkSection(substance[sectionName].przywozzue) == true && checkSection(substance[sectionName].przywozniezue) == true) {
        //            console.log("true");
        //            return true;
        //        } else {
        //
        //            if (subSectionName == 'przywozzue' && (substance[sectionName].przywozzue.countries != undefined && substance[sectionName].przywozzue.countries.length > 0)) {
        //                return true
        //            } else {
        //                return false
        //            }
        //
        //            if (subSectionName == 'przywozniezue' && substance[sectionName].przywozniezue.countries != undefined && substance[sectionName].przywozniezue.countries.length > 0) {
        //                return true
        //            } else {
        //                return false
        //            }
        //
        //
        //
        //            return false;
        //        }
        //    }
        //}

        function checkRequired(sectionName, subSectionName, substanceIndex) {
            //console.log(sectionName);
            if (sectionName == 'przywoz') {
                var substance = $scope.data.report_items[substanceIndex];
                //console.log(Object.keys(substance[sectionName].przywozzue).length, substance[sectionName]);
                //oba puste
                //if (substance[sectionName].przywozzue.value == undefined && substance[sectionName].przywozniezue.value == undefined) {
                if (checkSection(substance[sectionName].przywozzue) == true && checkSection(substance[sectionName].przywozniezue) == true) {
                    //console.log("true");
                    return true;
                } else {

                    if (subSectionName == 'przywozzue' && (substance[sectionName].przywozzue.countries != undefined && substance[sectionName].przywozzue.countries.length > 0)) {
                        return true
                    } else {
                        return false
                    }

                    if (subSectionName == 'przywozniezue' && substance[sectionName].przywozniezue.countries != undefined && substance[sectionName].przywozniezue.countries.length > 0) {
                        return true
                    } else {
                        return false
                    }


                    return false;
                }
            }

            if (sectionName == 'wywoz') {
                var substance = $scope.data.report_items[substanceIndex];
                //console.log(Object.keys(substance[sectionName].przywozzue).length, substance[sectionName]);
                //oba puste
                //if (substance[sectionName].przywozzue.value == undefined && substance[sectionName].przywozniezue.value == undefined) {
                if (checkSection(substance[sectionName].wywozdoue) == true && checkSection(substance[sectionName].wywuzniedoue) == true) {
                    //console.log("true");
                    return true;
                } else {

                    if (subSectionName == 'wywozdoue' && (substance[sectionName].wywozdoue.countries != undefined && substance[sectionName].wywozdoue.countries.length > 0)) {
                        return true
                    } else {
                        return false
                    }

                    if (subSectionName == 'wywozniezue' && substance[sectionName].wywuzniedoue.countries != undefined && substance[sectionName].wywuzniedoue.countries.length > 0) {
                        return true
                    } else {
                        return false
                    }


                    return false;
                }
            }
        }

        function checkSection(section) {

            if (section == undefined) {
                return false;
            }

            if (section.value == undefined && (section.products == undefined || section.products.length == 0)) {
                return true;
            }

            return false;
        }


        function checkIsSomethingFilled(section, withSection) {
            var t = true;


            angular.forEach(section, function (subSection, sectionName) {
                //console.log(subSection, sectionName);
                if (t == true) {
                    if (withSection == 'install' && reportsService.serviceInstalation(sectionName) == true && subSection.value != undefined) {
                        t = false;
                        return false;
                    }

                    if (withSection == 'inne' && reportsService.inneProcesy(sectionName) == true && subSection.value != undefined) {
                        t = false;
                        return;
                    }

                    if (withSection == 'inne' && sectionName == 'produkcja') {
                        //console.log(subSection, sectionName);
                        t = (subSection.products != undefined && $scope.wymaganaProdukcja == false) ? subSection.products.length == 0 : true;
                        return false;
                    }
                }
            });
            //console.log("t", t);
            return t;
        }

        function checkIsSomethingFilledOdzysk(section, withSection) {
            var t = true;

            angular.forEach(section, function (subSection, sectionName) {
                if (subSection.value != undefined) {
                    t = false;
                    return false;
                }
            });

            return t;
        }

        function getDictionaryProfiles() {
            profileDictionaryResource.get({}, function (response) {
                if (response.success == true) {
                    $scope.szwo = response.data.slice(0, 8);
                    $scope.fgc = response.data.slice(8, (response.data.length + 1));
                }
            });
        }

        function getCompanyProfile() {
            companyService.getCompanyProfile($scope.loggedUser.company.id).then(function (response) {
                $scope.dataProfile.items = response;
            });
        }


        function markChecked(item) {
            if ($scope.dataProfile.items.length != 0) {
                if ($scope.dataProfile.items.indexOf(item.id) != -1) {
                    $scope.data[item.name] = 1;

                    return true;
                }

                return false;
            }
        }

        function calculateMaxRecycling(substanceIndex) {
            var maxRecycling = 0;
            var substance = getSubstanceByIndex(substanceIndex);
            if (typeof substance.odzysk !== 'undefined' && typeof substance.odzysk.odzysk !== 'undefined') {
                maxRecycling += parseFloat(substance.odzysk.odzysk.value) || 0;
            }
            if (typeof substance.zapasy !== 'undefined' && typeof substance.zapasy.ilosc1 !== 'undefined') {
                maxRecycling += parseFloat(substance.zapasy.ilosc1.value) || 0;
            }
            if (typeof substance.obrot !== 'undefined' && typeof substance.obrot.zakup !== 'undefined') {
                maxRecycling += parseFloat(substance.obrot.zakup.value) || 0;
            }
            substance.maxRecycling = maxRecycling;
        }
        function checkMaxRecycling(substanceIndex) {
            var substance = getSubstanceByIndex(substanceIndex);
            if (typeof substance.odzysk !== 'undefined' && typeof substance.odzysk.recykling !== 'undefined') {
                if (substance.odzysk.recykling.value > substance.maxRecycling) {
                    substance.maxRecyclingError = true;
                    substance.odzysk.recykling.value = substance.maxRecycling;
                } else {
                  substance.maxRecyclingError = false;
                }
            }
        }
        function getSubstanceByIndex(substanceIndex) {
            return $scope.data.report_items[substanceIndex];
        }
    }]);
