parameters.service('ParametersService', [ 'ParametersResource', 'NgTableParams',
    function (ParametersResource, NgTableParams) {
        var vm = this;

        vm.page = 1;
        vm.count = 10;
        vm.defaultSorting = {'parameters.key': 'desc'};
        vm.filter = {};

        var service = {
            getNgTableParams: getNgTableParams
        };

        return service;


        function getNgTableParams () {
            return new NgTableParams({
                    page: vm.page,
                    count: vm.count,
                    sorting: vm.defaultSorting,
                    filter: vm.filter
                },
                {
                    total: 0, // length of data
                    getData: function ($defer, params) {

                        params.$params.q = params.$params.filter.name;

                        vm.page = params.page();
                        vm.count = params.count();
                        vm.defaultSorting = params.sorting();
                        vm.filter = params.filter();

                        return ParametersResource.get(params.url()).$promise.then(function (data) {
                            params.total(data.total);
                            return data.data;
                        });
                    }
                });
        }

        function response(response) {
            if (response.success == true) {
                return response.data;
            } else {
                console.warn(response);
            }
        }


    }]);