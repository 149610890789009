register.controller('NewAdminController', ['$scope','adminResource', '$state' , function ($scope, adminResource, $state) {

    $scope.loadingContent = true;
    $scope.$on('$includeContentLoaded', function(event) {
        $scope.loadingContent = false;
    });

    $scope.data = {
        admin: {
            email: '',
            nip: ''
        },
        user: {},
        documents: []
    };

    $scope.initForm = function (form) {
        $scope.adForm = form;
    };

    $scope.$watch('data.admin.email', function (newV, oldV){
        if (newV != oldV) {
            if (newV == undefined || newV == '') {
                $scope.adForm.adminEmailCheck.$setValidity('ngRemoteValidate', true);
            }
        }
    });

    $scope.$watch('data.admin.nip', function (newV, oldV){
        if (newV != oldV) {
            if (newV == undefined || newV == '') {
                $scope.adForm.nip.$setValidity('ngRemoteValidate', true);
            }
        }
    });



    $scope.sendingForm = false;
    $scope.errors = null;
    $scope.save = function (data) {
        $scope.errors = null;
        $scope.sendingForm = true;
        adminResource.changeAdmin(data, function (response) {
            $scope.sendingForm = false;
            if (response.success == true) {
                return $state.go("message");
            } else {
                $scope.errors = response.error;
            }
        });

    };

}]);