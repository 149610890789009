users.service('usersService', ['NgTableParams', 'usersResource', 'remindResource', 'toaster', 'temporaryAdminResource', 'companyResource',
    function ( NgTableParams, usersResource, remindResource, toaster, temporaryAdminResource, companyResource) {

        var vm = this;

        vm.page = 1;
        vm.count = 10;
        vm.defaultSorting = {'companies.created_at': 'desc'};
        vm.filter = {};

        var service = {
            getNgTableParams: getNgTableParams,
            save: save,
            sendEmail: sendEmail,
            delete: deleteUser,
            setNewAdmin: setNewAdmin,
            denyNewAdmin: denyNewAdmin,
            updateTemporaryAdmin: updateTemporaryAdmin,
            getTemporaryAdmin: getTemporaryAdmin,
            getCurrentAdmin: getCurrentAdmin,
            update: update,
            searchUser: searchUser,
			markDisclaimer: markDisclaimer
        };

        return service;


        function getNgTableParams() {
            return new NgTableParams({
                page: vm.page,
                count: vm.count,
                sorting: vm.defaultSorting,
                filter: vm.filter

            },
            {
                total: 0, // length of data
                getData: function ($defer, params) {

                    vm.page = params.page();
                    vm.count = params.count();
                    vm.defaultSorting = params.sorting();
                    vm.filter = params.filter();

                    return usersResource.get(params.url()).$promise.then(function (data) {
                        params.total(data.total);
                        return data.data;
                    });
                }
            });
        }


        function save(data) {
            return usersResource.save(data).$promise.then(checkResponse);
        }


        function update(id, data) {
            return usersResource.update({id: id}, data).$promise.then(updateResponse);
        }

        function updateResponse(response) {
            if (response.success == true) {
                toaster.pop('success', "Użytkownik został zaktualizowany");
                return response.data;
            } else {
                toaster.pop('error', 'Błąd zapisu');
                return false;
                console.warn("Blad zapisu", response.error);
            }
        }

        function checkResponse(response) {
            if (response.success == true) {
                toaster.pop('success', "Użytkownik został dodany");
                return response;
            } else {
                toaster.pop('error', 'Błąd zapisu');
                return false;
                console.warn("Blad zapisu", response.error);
            }
        }


        function sendEmail(email) {
            remindResource.save({email: email}, function (response) {

                if (response.success == true) {
                    toaster.pop('success', "Wiadomość z hasłem wysłana");

                } else {
                    toaster.pop('error', response.error);
                }
            });
        }


        function deleteUser(id) {
            return usersResource.delete({id: id}).$promise.then(showInfo);
        }


        function showInfo(response) {
            if (response.success == true) {
                toaster.pop('success', "Użytkownik został usunięty");
                return response;
            } else {
                toaster.pop('error', 'Błąd usuwania');
                console.warn("Blad zapisu", response.error);
            }
        }

        function setNewAdmin(userId) {
            return usersResource.update({id: userId, action: 'activate'}).$promise;
        }

        function denyNewAdmin(id, tempAdmin) {
            return usersResource.update({id: id, action: 'deny'}, {tempAdmin: tempAdmin != null ? tempAdmin.id : null}).$promise;
        }


        function updateTemporaryAdmin(data) {
            return temporaryAdminResource.update({id: data.id}, data).$promise.then(showResponseInfo)
        }

        function showResponseInfo(response) {
            if (response.success == true) {
                toaster.pop('success', 'Dane uaktualnione');
                return response.data;
            } else {
                toaster.pop('error', 'Bład aktualizacji');
                console.warn(response);
            }
        }

        function getTemporaryAdmin(currentAdminId) {
            return temporaryAdminResource.get({subId: currentAdminId, action: 'getByAdmin'}).$promise.then(manageResponse);
        }

        function getCurrentAdmin(companyId) {
            return companyResource.get({id: companyId, action: 'getAdmin'}).$promise.then(manageResponse);
        }
        ;

        function manageResponse(response) {
            if (response.success == true) {
                return response.data;
            } else {
                return null;
            }
        }

        function searchUser(txt) {
            return usersResource.get(txt).$promise.then(manageResponse);
        }
		
		function markDisclaimer(id) {    			
			window.location.assign("/users/"+id+"/disclaimer");			
        }
		
    }]);
