/**
 * Created by jgolebiewski on 05.08.2016.
 */

(function () {
    'use strict';

    angular.module("App").controller('AppController', appController);

    appController.$inject = ['$scope', 'usersService', '$state', '$rootScope'];

    function appController($scope, usersService, $state, $rootScope) {

        $scope.savingPassword = false;
        $scope.data = {};
        $scope.updatePassword = updatePassword;
        $scope.updateDisclaimers = updateDisclaimers;


        function updatePassword(data) {
            $scope.savingPassword = true;

            data.updatePassExpired = 1;

            usersService.update($scope.loggedUser.id, data).then(function (resp) {
                $scope.savingPassword = false;

                if (resp != false) {
                    $rootScope.passwordExpired = false;
                    $scope.loggedUser.passExpiration = resp.passExpiration;
                    return $state.go('dashboard');
                }

            });
        }

        function updateDisclaimers(data) {
            $scope.savingPassword = true;

            //jak zapis udany to ustawiamy
            $rootScope.disclaimers = false;

            //w profilu odpowienie pola też
            // $scope.loggedUser.disclaimers = resp.disclaimers;		
			usersService.markDisclaimer($scope.loggedUser.id);
        }

    }

})();
