/**
 * Created by jgolebiewski on 07.12.2015.
 */
reports.controller('PreviewReportController', PreviewReportController);

PreviewReportController.$inject = [
    '$scope',
    '$sce',
    'reportEntity',
    'profileDictionaryResource',
    '$stateParams',
    'reportsService',
    '$uibModal',
    '$state',
    'toaster',
    '$http',
    'companyService',
    'MessagesTemplatesService'
];

function PreviewReportController($scope, $sce, reportEntity, profileDictionaryResource, $stateParams, reportsService, $uibModal, $state, toaster, $http, companyService, MessagesTemplatesService) {

	const TEMPLATE_CATEGORY_ID = 4;

    if (typeof reportEntity == 'undefined' || typeof reportEntity.data == 'undefined') {
        toaster.pop('error', 'Nie znaleziono danych sprawozdania');
        $state.go('reports.list');
    } else {
        var vm = this;

        vm.modalAcceptInstance = null;

        $scope.summerNoteOptions.height = 300;
        $scope.data = reportEntity.data;
        $scope.report = reportEntity.data;
        $scope.dataProfile = {items: []};
        $scope.szwo = null;
        $scope.fgc = null;
        $scope.id = $stateParams.id;
        $scope.checkedReport = null;
        //do manipulacji widokiem z edycja i przkazywaniem sprawozdania
        $scope.isEditMode = true;

        $scope.markChecked = markChecked;
        $scope.sendReport = sendReport;
        $scope.openAcceptModal = openAcceptModal;
        $scope.confirm = confirm;
        $scope.cancel = cancel;

        $scope.openIncorrectModal = openIncorrectModal;
        $scope.openCorrectionModal = openCorrectionModal;
        $scope.markIncorrect = markIncorrect;
        $scope.verify = verify;

        $scope.hasConfirmedReport = true;
        $scope.hasSubmitedReport = true;
        $scope.hasIncorrectReport = false;

        var data = { id: reportEntity.data.id };

        reportsService.preview(data).then(function(response) {
            $scope.reportPreview = $sce.trustAsHtml(response.data);
        });

        companyService.hasConfirmedReport(reportEntity.data.companyId, reportEntity.data.reportYear).then(function(response) {
            $scope.hasConfirmedReport = response.hasConfirmedReport;
        });

        reportsService.hasSubmitedReport(reportEntity.data.id).then(function(response) {
            $scope.hasSubmitedReport = response.data;
        });

        reportsService.hasIncorrectReport(reportEntity.data.id).then(function(response) {
            $scope.hasIncorrectReport = response.data;
        });

        companyService.getCompanySections(reportEntity.data.companyId, reportEntity.data.reportYear).then(function(sections) {
            $scope.sections = sections.data;
        });
        getDictionaryProfiles();
        verify(data);
    }
    //function getCompanyProfile() {
    //    companyService.getCompanyProfile($scope.data.companyId).then(function (response) {
    //        console.log(response);
    //        $scope.dataProfile.items = response;
    //    });
    //}

    function verify(formData) {
        reportsService.verifyReport(formData).then(function (data) {
            $scope.sendingForm = false;
            toaster.pop(data.status, 'Wynik weryfikacji: ' + data.message);
            $scope.verifyResult = data;
        });
    }

    function getDictionaryProfiles() {
        profileDictionaryResource.get({year: reportEntity.data.reportYear}, function (response) {
            if (response.success == true) {
                $scope.szwo = response.data.slice(0, 8);
                $scope.fgc = response.data.slice(8, (response.data.length + 1));
            }
        });
    }

    function markChecked(item) {
        return reportEntity.data[item.name];
    }

    function sendReport() {
        reportsService.sendReport(reportEntity).then(function (response) {

        });
    }

    function openAcceptModal() {


        vm.modalAcceptInstance = $uibModal.open({
            templateUrl: appRoot + '/reports/views/modals/accept.modal.html',
            size: 'small',
            animation: true,
            scope: $scope,
            resolve: {
                items: function () {
                    return null;
                }
            }
        });

        vm.modalAcceptInstance.opened.then(function (selectedItem) {
            $scope.checking = true;
            reportsService.checkReportExists({year: $scope.data.reportYear, company: $scope.data.companyId}).then(function (data) {
                $scope.checkedReport = data;
                $scope.checking = false;
            })


        }, function () {
            console.info('Modal dismissed at: ' + new Date());
        });
    }

    function openCorrectionModal() {
        vm.modalCorrectionInstance = $uibModal.open({
            templateUrl: appRoot + '/reports/views/modals/correction.modal.html',
            size: 'od-lg',
            animation: true,
            scope: $scope,
            resolve: {
                items: function () {
                    return null;
                }
            }
        });

        vm.modalCorrectionInstance.opened.then(function () {
            $scope.sendCorrectionMessage = function(text) {
                $scope.sending = true;
                reportsService.correctionMessage({id: $scope.id, body: text}).then(function(response) {
                	if (!$scope.data.correction_message) {
                		$scope.data.correction_message = response.data;
                	} else {
                		$scope.data.again_correction_message = response.data;
                	}
                    $scope.data.correction_messages.push(response.data);
                    vm.modalCorrectionInstance.close();
                    $scope.sending = false;
                    toaster.pop('success', 'Wiadomość wysłana');
                }, function(reponse) {
                    $scope.sending = false;
                    toaster.pop('error', 'Nie udało się wysłać wiadomości');
                });
            }
            $scope.cancel = function() {
                vm.modalCorrectionInstance.close();
            }
            $scope.searchTemplates = function (searchTxt) {
                $scope.templatesLoading = true;
                MessagesTemplatesService.getTemplates(searchTxt, TEMPLATE_CATEGORY_ID).then(function (response) {
                    if (response.success == true) {
                        $scope.templates = response.data;
                        $scope.templatesLoading = false;
                        if (!$scope.correctionMessage) {
                        	$scope.correctionMessage = $scope.templates[0].body;
                        }
                    }
                });
            };
            $scope.setBodyTemplate = function (selectedItem) {
                $scope.correctionMessage = selectedItem.body;
            };
            $scope.templates = [];
            $scope.templatesLoading = false;
            $scope.searchTemplates('');

            reportsService.messages($scope.id).then(function(response) {
                $scope.messages = response.data;
            });
        }, function () {
            console.info('Modal dismissed at: ' + new Date());
        });
    }

    function confirm() {
        $scope.saving = true;
        reportsService.confirmReport($scope.data).then(function (data) {
            $scope.saving = false;
            cancel();
            $state.reload();
        });
    }

    function cancel() {

        if (vm.modalAcceptInstance) {
            vm.modalAcceptInstance.close();
        }

    }

    function openIncorrectModal() {
        vm.modalAcceptInstance = $uibModal.open({
            templateUrl: appRoot + '/reports/views/modals/incorrect.modal.html',
            size: 'small',
            animation: true,
            scope: $scope,
            resolve: {
                items: function () {
                    return null;
                }
            }
        });
    }

    function markIncorrect() {
        $scope.saving = true;
        reportsService.markIncorrectReport($scope.report).then(function(data){
            $scope.saving = false;
            cancel();
            $state.reload();
        });
    }

    $scope.openWronglySentModal = function() {
        vm.modalAcceptInstance = $uibModal.open({
            templateUrl: appRoot + '/reports/views/modals/wrongly-sent.modal.html',
            size: 'small',
            animation: true,
            scope: $scope,
            resolve: {
                items: function () {
                    return null;
                }
            }
        });
    }

    $scope.markWronglySent = function() {
        $scope.saving = true;
        reportsService.markWronglySent($scope.report).then(function(data){
            $scope.saving = false;
            cancel();
            $state.reload();
        });
    }
}
