users.service('suService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'SU_TYPES', 'LOGGED_USER', function ($http, uiGridConstants, moment, gridService, SU_TYPES, LOGGED_USER) {
    var self = this;

    self.resource = null;

    self.gridApi = null;

    self.scope = null;

    self.deviceCategories = [];

    self.resourceParams = {};

    var gridColumns = [
        {
            name: 'Imię i nazwisko',
            field: "surname",
            fieldType: 'text',
            cellTemplate: '<div class="ui-grid-cell-contents"><span ng-show="row.entity.suType !== \'Konto Techniczne\'">{{ row.entity.firstName }} {{ row.entity.surname }}</span>' +
                        '<span ng-show="row.entity.suType === \'Konto Techniczne\'"><a title="Urządzenia operatora" href="#/users/company/{{ row.entity.companyId }}">{{ row.entity.firstName }} {{ row.entity.surname }}</a></span></div>'
        },
        {
            name: 'Adres e-mail',
            field: "email",
            minWidth: 200,
            fieldType: 'text',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.email }}</div>'
        },
        {
            name: 'Uprawnienia',
            width: '*',
            field: 'suType',
            fieldType: 'text',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.suType }}<span title="{{row.entity.role.fullname}}" ng-show="row.entity.suType === \'Konto Techniczne\'"> [{{ row.entity.role.name | limitTo:1:0 }}]</span></div>',
            filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
            '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
            '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
            filter: {
                options: {}
            }
        },
        {
            name: "Status",
            width: '10%',
            //minWidth: 150,
            enableFiltering: true,
            enableSorting: false,
            enableColumnMenu: false,
            fieldType: 'text',
            field: 'users.status',
            cellTemplate: function () {
                return '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 0"><div class="label label-success">Aktywny</div></div>' +
                    '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 1"><div class="label label-danger">Zablokowany</div></div>'
            },

            filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
            '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
            '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
            filter: {
                //term: 0,
                options: [{id: 0, label: 'Aktywny'}, {id: 1, label: 'Zablokowany'}]
            }
        },
		{
            name: 'Ważność hasła',
            field: "passExpiration",
            fieldType: 'dateRange',
            enableFiltering: true,
            cellFilter: 'date:"longDate"',
            filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
            cellTemplate: '<div class="ui-grid-cell-contents r">' +
                '<i title="Hasło wygasło" class="fa fa-warning" ng-show="grid.appScope.hasPasswordExpired(row.entity.passExpiration)"></i> ' +
                '<small title="{{ row.entity.passExpiration }}" am-time-ago="row.entity.passExpiration"></small>' +
                '</div>'
        },
		{
            name: 'Ostatnie logowanie',
            field: "lastLogin",
            fieldType: 'dateRange',
            enableFiltering: true,
            cellFilter: 'date:"longDate"',
            filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
            cellTemplate: '<div class="ui-grid-cell-contents r"><em><small title="{{ row.entity.lastLogin }}" am-time-ago="row.entity.lastLogin"></small></em></div>'
        }
    ];

    var actionsColumn = {
        name: "Akcje",
        fieldType: 'text',
        width: '12%',
        //minWidth: 150,
        enableFiltering: false,
        enableSorting: false,
        enableColumnMenu: false,
        cellTemplate: '<div class="ui-grid-cell-contents"><a ng-show="grid.appScope.canEdit(row.entity)" data-ui-sref="users.su.edit({id: row.entity.id})" class="btn btn-primary btn-xs"><i class="fa fa-edit"></i> <span class="hidden-sm hidden-xs">Edytuj</span></a></div>'
    };

    self.getGridOptions = function () {
        gridService.query.type = 1;
        gridService.resource = self.resource;
        gridService.scope = self.scope;
        gridService.resourceParams = self.resourceParams;

        var gridOptions = gridService.getGridOptions();

        gridOptions.columnDefs = self.getGridColumns();
        return gridOptions;
    };

    self.getGridColumns = function () {
        gridColumns.push(actionsColumn);

        return gridColumns;
    };

    self.getDateRangePickerOptions = function () {
        return dateRangePickerOptions;
    };

    self.getPage = function (){
        return gridService.getPage();
    };

    function canEdit(user) {
        if (LOGGED_USER.suType == SU_TYPES.su || LOGGED_USER.suType == SU_TYPES.test) {
            return false;
        }
        if (LOGGED_USER.suType == SU_TYPES.su_it) {
            return true;
        }
        if (user.suType == SU_TYPES.su || user.suType == SU_TYPES.test) {
            return true;
        }

        return false;
    }
    self.canEdit = canEdit;

    return self;

}]);