/**
 * Created by jgolebiewski on 03.11.2015.
 */

common.directive('captcha', ['$timeout', function ($timeout) {
    return {
        template: '<ng-form name="captcha">' +
            '<div class="captcha-container clearfix"> ' +
        '<div class="captcha-desc"> ' +
        '<label >Wykonaj działanie z obrazka</label> ' +
        '</div> ' +
        '<div class="captcha-regenerate"><a href data-ng-click="regenerate()" class="btn btn-sm btn-link"><i class="fa fa-refresh"></i></a></div>'+
        '<div class="captcha-img"> ' +
            '<div id="con"> </div>' +
        '</div> ' +
        '<div class="captcha-input"> ' +
        '<input type="number" name="captcha" autocomplete="off" class="form-control" id="inputCaptcha" min="0" required data-ng-model="data.sum" /> ' +
        '<div ng-show="auth.$submitted"> ' +
        '<span class="text-danger" data-ng-show="captcha.captcha.$error.required">Wprowadź wynik</span>' +
        '</div> ' +
        '</div>' +
        '</div>'+
        '</ng-form>',
        restrict: 'A',
        require: 'ngModel',
        controller: ['$scope', '$attrs', '$window', function ($scope, $attrs, $window) {

            //$scope.url = 'http://' + window.location.host + "/captcha";

            $scope.regenerate = function () {
                var t = new Date().getTime();
                var url = $scope.url.split('?');
                angular.element('#imgcap').remove();
                angular.element('#con').html('<img src="' + url[0] + '?t=' + t + '" id="imgcap" />');
            };


        }],
        link: function (scope){
            var t = new Date().getTime();
            scope.url =  "/captcha?t=" + t;
            scope.regenerate();
            scope.$watch('errors', function(value){
                //console.log("watch",value);
                if (value != undefined && value != '' && value != null) {
                    scope.regenerate();
                }

            });
        }
    };
}]);