reports.directive('editProfile', ['$uibModal', '$state', 'companyService', 'VERSION', 'toaster', function ($uibModal, $state, companyService, VERSION, toaster) {
    return {
        restrict: 'E',
        scope: true,
        template: '<button class="btn btn-default" ng-click="editProfileModal()">Zmień</button>',
        link: function (scope, element, attrs) {
            scope.VERSION = VERSION;

            scope.editProfileModal = function () {
                scope.processing = false;

                scope.modalInstance = $uibModal.open({
                    templateUrl: appRoot + '/reports/views/modals/profile.modal.html',
                    size: 'od-lg',
                    animation: true,
                    backdrop: 'static',
                    keyboard: false,
                    scope: scope,
                    resolve: {
                        szwo: function () {
                            return scope.szwo;
                        },
                        fgc: function () {
                            return scope.fgc;
                        }
                    }
                });

                scope.cancel = function() {
                    scope.modalInstance.close();
                }
            };

            scope.updateProfile = function () {
                if (scope.dataProfile.items == undefined || scope.dataProfile.items.length == 0) {
                    return toaster.pop('warning', "Wybierz profil działalności firmy");
                }
                scope.processing = true;
                scope.save(scope.data, false);
                companyService.updateProfile(scope.loggedUser.company.id, scope.dataProfile.items).then(function(response){
                    scope.processing = false;
                    if (response.success == true) {
                        toaster.pop('success', 'Profil został uaktualniony');
                        $state.reload();
                    } else {
                        toaster.pop('error', response.error);
                    }
                });
            };
        }
    }
}]);