/**
 * Created by jgolebiewski on 24.07.2015.
 */
/*
 * Szablon modułu
 */


var messages = angular.module("messages", [
    "ui.router","roles","angularUtils.directives.dirPagination"
]);

messages.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES', function ($stateProvider, $urlRouterProvider, USER_ROLES) {

    $urlRouterProvider.otherwise("/dashboard");

    $stateProvider
        .state('messages', {
            url: "/messages/{type}",
            templateUrl: appRoot + "messages/views/messages.html",
            controller: "MessagesController",
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            },
            ncyBreadcrumb: {
                label: 'Wiadomości'
            }
        })

        .state('templates', {
            url: '/templates',
            views: {
                '@': {
                    templateUrl: appRoot + 'messages/views/templates.list.html',
                    controller: 'MessagesTemplatesListController'
                }
            },
            ncyBreadcrumb: {
            	parent: 'messages',
                label: 'Lista szablonów'
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })

        .state('templates.edit', {
            url: '/edit/{id:int}',
            views: {
                '@': {
                    templateUrl: appRoot + 'messages/views/template.html',
                    controller: 'MessagesTemplateController'
                }
            },
            ncyBreadcrumb: {
            	parent: 'templates',
                label: 'Szablon'
            },
            resolve: {
                id: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
                templateEntity: ['$stateParams', 'MessagesTemplatesResource', function ($stateParams, MessagesTemplatesResource) {
                    return MessagesTemplatesResource.get({ id: $stateParams.id }).$promise;
                }]
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })

        .state('templates.new', {
            url: '/new',
            views: {
                '@': {
                    templateUrl: appRoot + 'messages/views/template.html',
                    controller: 'MessagesTemplateController'
                }
            },
            ncyBreadcrumb: {
                parent: 'templates',
                label: 'Nowy szablon'
            },
            resolve: {
            	id: function() {
                    return null;
                },
                templateEntity: function () {
                    return null;
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })
}]);