users.directive('companyProfileStatus', function directive() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            status: '=status'
        },
        templateUrl: appRoot + 'users/directives/companyProfileStatus.tpl.html',
        link: function() {}
    }
});
