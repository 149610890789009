/**
 * Created by jgolebiewski on 07.12.2015.
 */

users.factory('substanceResource', ['$resource', function ($resource) {
    return $resource('/substance/:id', {id: '@id'}, {
        update: {
            method: 'PUT'
        },
        show: {
            params: {
                'action': 'show'
            }
        },
    });
}]);