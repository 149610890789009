users.controller('AccountsToDeleteController', ['$scope', 'toCloseService', 'companyResource', function ($scope, toCloseService, companyResource) {

	$scope.listName = 'AccountsToDeleteList';

        toCloseService.scope = $scope;
        toCloseService.resource = companyResource;
        toCloseService.resourceParams = {action: 'toclose'};
        $scope.gridOptions = toCloseService.getGridOptions();

        toCloseService.getPage();
}]);