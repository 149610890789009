reports.service('companyReportsGridService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'LOGGED_USER', 'USER_ROLES','reportsResource','companyResource','toaster',
    function ($http, uiGridConstants, moment, gridService, LOGGED_USER, USER_ROLES, reportsResource, companyResource, toaster) {
        var self = this;

        self.resource = null;

        self.gridApi = null;

        self.scope = null;

        self.resourceParams = {};

        var dateRangePickerOptions = {
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Wybierz",
                "cancelLabel": "Anuluj",
                "fromLabel": "Od",
                "toLabel": "Do",
                "customRangeLabel": "Zdefiniuj"
            },
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
                'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
                'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
                'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        var gridColumns = [
            {
                name: "Rok",
                width: '80',
                field: "reportYear",
                fieldType: 'text'
            },
            {
                name: 'Nazwa',
                width: '15%',
                minWidth: 200,
                field: "reports.name",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.name }}</div>'
            },
            {
                name: 'Data utworzenia',
                width: '*',
                field: 'reports.created_at',
                fieldType: 'dateRange',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.created_at | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Data aktualizacji',
                width: '*',
                field: 'reports.updated_at',
                fieldType: 'dateRange',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.updated_at | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Korekta',
                width: '*',
                field: 'cm.createdAt',
                fieldType: 'dateRange',
                visible: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.correctionMessage | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: "Status",
                enableFiltering: true,
                enableSorting: false,
                enableColumnMenu: false,
                fieldType: 'text',
                width: '9%',
                field: 'reports.status',
                cellTemplate: function () {
                    return '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 1"><div class="label label-default">Szkic</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 2"><div class="label label-primary">Wysłane</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 3"><div class="label label-success">Zatwierdzone</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 4"><div class="label label-danger">Odrzucone</div></div>'+
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 5"><div class="label label-danger">Błędne</div></div>'+
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 6"><div class="label label-info">Zerowe</div></div>'+
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 7"><div class="label label-info">Błędnie wysłane</div></div>'
                },
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
                '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    options: [{id: 1, label: 'Szkic'}, {id: 2, label: 'Wysłane'}, {id: 3, label: 'Zatwierdzone'}, {id: 4, label: 'Odrzucone'}
                             ,{id: 5, label: 'Błędne'}, {id: 6, label: 'Zerowe'}, {id: 7, label: 'Błędnie wysłane'}]
                }
            },
            {
                name: "Podgląd",
                fieldType: 'text',
                width: '9%',
                //minWidth: 150,
                enableFiltering: false,
                enableSorting: false,
                enableColumnMenu: false,
                cellTemplate: '<div class="ui-grid-cell-contents">' +
                '<a href="#/reports/preview/{{ row.entity.id }}" class="btn btn-default btn-xs"><i class="fa fa-search"></i> <span class="hidden-sm hidden-xs">Podgląd</span></a>'
            }

        ];


        self.getGridOptions = function () {
            gridService.query.type = 0;
            gridService.resource = self.resource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = self.getGridColumns();
            return gridOptions;
        };

        self.getGridColumns = function () {

            return gridColumns;

        };

        self.getDateRangePickerOptions = function () {
            return dateRangePickerOptions;
        };

        self.getPage = function () {
            return gridService.getPage();
        };

    }]);