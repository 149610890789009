/**
 * Created by jgolebiewski on 24.07.2015.
 */
/*
 * Szablon modułu
 */


var parameters = angular.module("parameters", [
    "ui.router","roles"
]);

parameters.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES', function ($stateProvider, $urlRouterProvider, USER_ROLES) {

    $urlRouterProvider.otherwise("/dashboard");

    $stateProvider
        .state('parameters', {
            url: "/parameters",
            templateUrl: appRoot + "parameters/views/parameters.list.html",
            controller: "ParametersListController",
            data: {
                authorizedRoles: [USER_ROLES.su]
            },
            ncyBreadcrumb: {
                label: 'Parametry systemu'
            }
        })
        .state('parameters.edit', {
            url: '/edit/{key}',
            views: {
                '@': {
                    templateUrl: appRoot + 'parameters/views/parameters.edit.html',
                    controller: 'ParametersEditController'
                }
            },
            ncyBreadcrumb: {
            	parent: 'parameters',
                label: 'Edytuj parametr'
            },
            resolve: {
                parameterEntity: ['$stateParams', 'ParametersResource', function ($stateParams, ParametersResource) {
                    return ParametersResource.get({ key: $stateParams.key }).$promise;
                }]
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })
}]);