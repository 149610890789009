users.directive('userRole', ['rolesResource', function (rolesResource) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            name: '=name'
        },
        template: "<span>{{ role.fullname }}</span>",
        link: function(scope) {
            scope.role = rolesResource.getRoleByName(scope.name);
        }
    }
}]);
