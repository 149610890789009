users.controller('CompanyUsersListController', ['$scope', '$q', 'companyEntity', 'companyService','usersService','$uibModal','companyResource','companyUsersGridService',
    function ($scope, $q,  companyEntity, companyService, usersService, $uibModal, companyResource, companyUsersGridService) {

        $scope.listName = 'CompanyUsersList';

        $scope.company = companyEntity.data;


        companyUsersGridService.scope = $scope;
        companyUsersGridService.resource = companyResource;
        companyUsersGridService.resourceParams = {action: 'users', id: companyEntity.data.id};
        $scope.gridOptions = companyUsersGridService.getGridOptions();

        companyUsersGridService.getPage();


        $scope.open = function (id) {

            $scope.idToDelete = id;
            $scope.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/common/views/modals/delete.modal.html',
                size: 'small',
                animation: true,
                scope: $scope,
                resolve: {
                    items: function () {
                        return $scope.items;
                    }
                }
            });
        };

        $scope.cancel = function () {
            $scope.modalInstance.close();
            $scope.idToDelete = null;
        };

        $scope.delete = function () {
            usersService.delete($scope.idToDelete).then(function () {
                $scope.idToDelete = null;
                MessagesTemplatesService.getPage();
                $scope.modalInstance.close();
            });
        };


        $scope.status = function(status) {
            var def = $q.defer();

            def.resolve([
                {id: 0, title: 'Aktywny'},
                {id: 1, title: 'Zablokowany'},
            ]);
            return def;
        };

    }]);