
App.factory('MenuService', ['$http', 'LOGGED_USER', 'USER_ROLES', function ($http, LOGGED_USER, USER_ROLES) {
    var sidebarService = {
        getItems: function () {
            var roles = USER_ROLES;
            var menu = [
                {
                    access: [roles.su ,roles.admin, roles.user],
                    state: 'dashboard',
                    title: null,
                    icon: 'fa fa-lg fa-fw fa-home',
                    submenu: []
                },
                {
                    access: [roles.su ,roles.admin, roles.user],
                    state: 'reports.list',
                    title: 'Lista sprawozdań',
                    icon: null,
                    submenu: []
                },
                {
                    access: [roles.su ],
                    state: 'notifications',
                    title: 'Zdarzenia',
                    icon: null,
                    submenu: []
                },
                {
                    access: [roles.su],
                    state: 'export.list',
                    title: 'Raporty',
                    icon: null,
                    submenu: []
                },
                {
                   access: [roles.su ,roles.admin, roles.user],
                   state: 'messages',
                   title: 'Wiadomości',
                   icon: null,
                   submenu: []
                },
                {
                    access: [roles.su, roles.admin, roles.user],
                    state: 'news',
                    title: 'Ogłoszenia',
                    icon: null,
                    submenu: []
                },
                {
                    access: [roles.su],
                    state: 'notices',
                    title: 'Komunikaty',
                    icon: null,
                    submenu: []
                },
                {
                    access: [roles.su ,roles.admin],
                    state: 'users.list',
                    title: 'Użytkownicy',
                    icon: 'icon-home',
                    submenu: [
                        {
                            access: [roles.su, roles.admin],
                            state: 'users.list',
                            title: 'Konta',
                        },
                        {
                            access: [roles.su],
                            state: 'users.registers',
                            title: 'Rejestracje',
                        },
                        {
                            access: [roles.su],
                            state: 'users.adminChange',
                            title: 'Zmiana Administratora',
                            icon: null,
                            submenu: []
                        },
                        {
                            access: [roles.su],
                            state: 'users.deletes',
                            title: 'Konta do zamknięcia',
                            icon: null,
                            submenu: []
                        },
						{
                            access: [roles.su],
                            state: 'users.su',
                            title: 'Lista kont wewnętrznych',
                        },
                    ]
                },
                {
                    access: [roles.su],
                    state: 'stats',
                    title: 'Statystyki',
                    icon: null,
                    submenu: []
                },
                //{
                //    access: [roles.su, roles.user, roles.admin],
                //    state: 'news',
                //    title: 'Ogłoszenia',
                //    icon: null,
                //    submenu: []
                //},

                {
                    access: [roles.su],
                    state: null,
                    title: 'Ustawienia',
                    icon: null,
                    submenu: [
                    	{
                            access: [roles.su],
                            state: 'dictionaries.list',
                            title: 'Substancje',
                            icon: null,
                            submenu: []
                        },
                        {
                            access: [roles.su],
                            state: 'templates',
                            title: 'Szablony wiadomości',
                            icon: null,
                            submenu: []
                        },
                        {
                            access: [roles.su],
                            state: 'parameters',
                            title: 'Parametry systemu'
                        }
                    ]
                },
                {
                    access: [roles.su],
                    state: 'removed',
                    title: 'Usunięte dane',
                    icon: null,
                    submenu: []
                },
            ];

            if (LOGGED_USER.role.name === roles.su) {
                return menu;
            }


            var filteredMenu = [];
            angular.forEach(menu, function (value, key) {
                if (sidebarService.checkAccess(value.access)) {
                    if (value.submenu.length === 0) {
                        filteredMenu.push(value);
                    } else {
                        var tmpSubmenu = [];
                        angular.forEach(value.submenu, function (sub, key) {
                            if (sidebarService.checkAccess(sub.access)) {
                                tmpSubmenu.push(sub);
                            }
                        });
                        value.submenu = tmpSubmenu;
                        filteredMenu.push(value);
                    }
                }
            });

            return filteredMenu;
        },
        checkAccess: function (permissions) {
            return permissions.indexOf(LOGGED_USER.role.name) !== -1;
        }
    };

    return sidebarService;
}]);