/**
 * Created by jgolebiewski on 05.08.2016
 */

(function () {
    messages.directive('messagesHistory', messagesDirective);

    messagesDirective.$inject = [];

    function messagesDirective() {

        var directive = {
            restrict: 'A',
            scope: {
                user: '=',
                type: '='
            },
            templateUrl: appRoot + '/messages/views/directives/messages.history.directive.html',
            controller: messagesHistoryDirectiveController,
            link: messagesLink
        };

        return directive;

        function messagesLink(scope, element, attr, ngModel) {

        }
    }

    messagesHistoryDirectiveController.$inject = ['$scope','$uibModal', 'messagesService'];

    function messagesHistoryDirectiveController($scope, $uibModal, messagesService) {




        var vm = this;
        $scope.open = open;
        $scope.pageChanged = pageChanged;
        $scope.cancel = cancel;

        $scope.pageSize = 5;
        $scope.currentPage = 1;
        $scope.total = 0;
        $scope.messages = [];
        $scope.loadingMessages = false;

        function open() {

            vm.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/messages/views/directives/messages.modal.html',
                size: 'lg',
                scope: $scope

            });

            getMessagesForUser();
        }


        function getMessagesForUser() {

            var queryParams = {
                user: $scope.user.id,
                page: $scope.currentPage,
                limit:  $scope.pageSize
            };

            if (typeof $scope.type !== 'undefined') {
                queryParams.type = $scope.type;
            }

            $scope.loadingMessages = true;
            // $scope.messages = [];

            messagesService.getMessages(queryParams).then(function (data) {
                if (data != false) {
                    $scope.messages = data.data;
                    $scope.loadingMessages = false;
                    $scope.total = data.total;
                }
            })
        }

        function pageChanged(newPage) {
            $scope.currentPage = newPage;
            getMessagesForUser();
        }

        function cancel () {
            vm.modalInstance.dismiss('cancel');
        }
    }


})();