users.controller('RegistersListController', ['$scope', 'registerService','$q','registerResource', 
    function ($scope, registerService, $q, registerResource) {

        $scope.listName = 'RegistersList';

        registerService.scope = $scope;
        registerService.resource = registerResource;
        $scope.gridOptions = registerService.getGridOptions();

        registerService.getPage();
}]);