(function () {
  'use strict';

  angular.module('app.export')
    .controller('ExportController', ExportController);

  ExportController.$inject = [
    '$scope',
    'ExportService',
    'provincesService',
    'reportsService',
    'companyService',
    '$filter',
    '$window'
  ];

  function ExportController(
    $scope,
    ExportService,
    provincesService,
    reportsService,
    companyService,
    $filter,
    $window
  ) {
    var defaultCriteria = {
      substance: 'szwo',
      group_by: null
    };

	$scope.years = reportsService.getYears();
    $scope.cancelForm = cancelForm;
    $scope.getProvinceList = getProvinceList;
    $scope.getSectionFieldList = getSectionFieldList;
    $scope.initForm = initForm;
    $scope.onSelectCity = clearCompanyList;
    $scope.onSelectProvince = clearCompanyList;
    $scope.onSelectSection = onSelectSection;
    $scope.save = save;
    $scope.searchCities = searchCities;
    $scope.searchChemicals = searchChemicals;
    $scope.searchCompanies = searchCompanies;

	$scope.productScope = [
		{id:1, name:'Zawarte tylko w produktach i urządzeniach'},
		{id:2, name:'Nie zawarte w produktach i urządzeniach'},
		{id:3, name:'Wszystko'}
  ];


	//reportsService.getProductScopes();


    $scope.cityList = [];
    $scope.chemicalList = [];
    $scope.saving = false;
    // TODO: Trochę to chyba bez sensu, bo to obejście już gotowej kontrolki - brak uniwersalnej?
    $scope.tableParams = {filter: filterDateRange};

    $scope.dateRangePickerOptions = {
		autoUpdateInput: false,
        opens: 'left',
        startDate: new Date(),
        endDate: new Date(),
        locale: {
            'format': 'DD/MM/YYYY',
            'separator': ' - ',
            'applyLabel': 'Wybierz',
            'cancelLabel': 'Anuluj',
            'fromLabel': 'Od',
            'toLabel': 'Do',
            'customRangeLabel': 'Zdefiniuj'
        },
    };

    initialize();
    onSubstanceChange();

    //////////////////////////////////////////////
    function cancelForm() {
      $scope.exportForm.$setPristine();
      $scope.exportForm.$setUntouched();
      setDefaultCriteria();
    }

    function clearCompanyList(value, type) {
      if (value && $scope.reportCriteria.company) {
        var res = [];
        switch (type) {
          case 'province':
            res = $filter('filter')($scope.companyList, {province: value});
            break;
          case 'city':
            res = $filter('filter')($scope.companyList, {city: value.city});
            break;
        }
        if (!res.length) {
          delete $scope.reportCriteria.company;
        }
      }
      $scope.companyList = [];
    }

    function filterDateRange(range) {
      $scope.reportCriteria.submittedDate = range.range;
    }

    function getProvinceList() {
      if (!$scope.reportCriteria.city) {
        return $scope.provinceList;
      } else {
        // tylko województwo z wybranego już miasta
        var result = [];
        angular.forEach($scope.cityList, function(city) {
          if (city.city === $scope.reportCriteria.city) {
            result.push(city.province);
          }
        });
        return result;
      }
    }

    function getSectionFieldList(section) {
      return $filter('filter')($scope.sectionFieldsList, {sections_id: section});
    }

    function initForm(form) {
      $scope.exportForm = form;
    }

    function initialize() {
      setDefaultCriteria();

      ExportService.getSections().then(function(sections) {
        $scope.sectionList = sections;
      });
      ExportService.getSectionFields().then(function(fields) {
        $scope.sectionFieldsList = fields;
      });
      $scope.provinceList = provincesService.getProvincesWithoutNames();
      ExportService.getProductTypes().then(function(types) {
        $scope.productTypeList = types;
      });
    }

    $scope.$watch('reportCriteria.reportType', function(value) {
        if (['1','2'].indexOf(value) !== -1) {
            $scope.showCriteria = true;
        } else {
            $scope.showCriteria = false;
        }
    });

    function onSelectSection() {
      delete $scope.reportCriteria.sectionField;
    }

    function onSubstanceChange() {
      $scope.$watch('reportCriteria.substance', function() {
        if ($scope.reportCriteria.chemical) {
          delete $scope.reportCriteria.chemical;
        }
        $scope.chemicalList = [];
      });
    }

    function save() {
      if ($scope.exportForm.$valid) {
        var reportFilename = 'raport.csv';
        ExportService.generateReport(angular.copy($scope.reportCriteria)).then(function(hash) {
          $window.location.href = '/export/' + hash + '/' + reportFilename;
        });
      }
    }

    function searchCities(searchTxt) {
      if (searchTxt.length == 0) {
        return false;
      }

      var searchObj = {
        city: searchTxt,
        province: $scope.reportCriteria.province,
      };

      $scope.citiesLoading = true;

      companyService.getCities(searchObj).then(function (response) {
        $scope.cityList = response.data;
      }).finally(function() {
        $scope.citiesLoading = false;
      });
    }

    function searchChemicals(searchTxt) {
      if (searchTxt.length == 0) {
        return false;
      }

      $scope.chemicalsLoading = true;

      var type = $scope.reportCriteria.substance.toUpperCase();

      reportsService.getChemicals(searchTxt, 'q', type, null, $scope.reportCriteria.reportYear).then(function (data) {
        $scope.chemicalList = data;
      }).finally(function() {
        $scope.chemicalsLoading = false;
      });
    }

    function searchCompanies(searchTxt) {
      if (searchTxt.length == 0) {
        return false;
      }

      var searchObj = {
        q: searchTxt,
        city: $scope.reportCriteria.city,
        province: $scope.reportCriteria.province
      };

      $scope.companyLoading = true;

      companyService.getCompanies(searchObj).then(function (response) {
        $scope.companyList = response.data;
      }).finally(function() {
        $scope.companyLoading = false;
      });
    }

    function setDefaultCriteria() {
      $scope.reportCriteria = angular.copy(defaultCriteria);
    }
  }
})();