/**
 * Created by jgolebiewski on 24.07.2015.
 */


var register = angular.module("register", [
    "ui.router","remoteValidation", "common", 'validation.match'
]);

register.config(['$stateProvider', '$urlRouterProvider', '$compileProvider', '$httpProvider',function ($stateProvider, $urlRouterProvider, $compileProvider, $httpProvider) {

    $urlRouterProvider.otherwise("/");

    $stateProvider
        .state('register', {
            url: "/register",
            templateUrl: appRoot + "register/views/register.html",
            controller: "RegisterController"
        })
        .state('message', {
            url: "/message",
            templateUrl: appRoot + "register/views/message.html",
            controller: ['$scope', function ($scope){}]
        })
        .state('change_admin', {
            url: "/nowy-administrator",
            templateUrl: appRoot + "register/views/new.admin.html",
            controller: "NewAdminController"
        });

    $compileProvider.debugInfoEnabled(false);
    $httpProvider.interceptors.push('LoaderInterceptor');

}]);

register.directive('uniform',['$timeout',function($timeout){
    return {
        restrict:'A',
        require: 'ngModel',
        link: function(scope, element, attr, ngModel) {
            element.uniform({useID: false});
            scope.$watch(function() {return ngModel.$modelValue}, function() {
                $timeout(jQuery.uniform.update, 0);
            } );
        }
    };
}]);