/**
 * Created by jgolebiewski on 19.10.2015.
 */

'use strict';

var App = angular.module("App", [
    "ui.router",
    //'ngAnimate',
    //"ngSanitize",
    'ngResource',
    'angularMoment',
    'ngTable',
    'ui.grid',
    'ui.grid.pagination',
    'ui.bootstrap',
    'webStorageModule',
    "remoteValidation",
    'validation.match',
    //'uiSwitch',
    'ui.select',
    'ui.mask',
    'toaster',
    'ncy-angular-breadcrumb',
    'daterangepicker',
    'checklist-model',

    //'angular-loading-bar',
    'common',
    'roles',
    'news',
    'notifications',
    'notices',
    'reports',
    'users',
    'register',
    'auth',
    'messages',
    'app.export',
    'app.stats',
    'LocalStorageModule',
    'parameters',
    'dictionaries',
    'removed'
]);


fetchData().then(bootstrapApplication);

function fetchData() {
    var initInjector = angular.injector(["ng"]);
    var $http = initInjector.get("$http");

    return $http.get("/users/info").then(function (response) {

        if (response.data.success == false) {
            App.constant("LOGGED_USER", null);
            //window.location.href = '/';
            return false;
        }
        App.constant("LOGGED_USER", response.data.data);


    }, function (errorResponse) {
        //console.log('errorResponse', errorResponse);
        window.location.href = "/";
        return false;
    });
}

function bootstrapApplication() {
    angular.element(document).ready(function () {
        angular.bootstrap(document, ["App"], {
            strictDi: true
        });

    });
}

var __version_number;
App.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$compileProvider', 'USER_ROLES', '$breadcrumbProvider','localStorageServiceProvider',
    function ($stateProvider, $urlRouterProvider, $httpProvider, $compileProvider, USER_ROLES, $breadcrumbProvider, localStorageServiceProvider) {

        $urlRouterProvider.otherwise("/");

        $stateProvider
            .state('dashboard', {
                url: "/dashboard",
                templateUrl: appRoot + "application/views/dashboard.html",
                controller: "DashboardController",
                data: {
                    authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
                },
                ncyBreadcrumb: {
                    label: 'Start'
                }
            })
            .state('passwordExpired', {
                url: "/password-expired",
                templateUrl: appRoot + "application/views/change.password.html",
                controller: "AppController",
                data: {
                    authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('disclaimers', {
            url: "/disclaimers",
            templateUrl: appRoot + "application/views/disclaimers.html",
            controller: "AppController",
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            },
            ncyBreadcrumb: {
                skip: true
            }
        });

        localStorageServiceProvider.setPrefix('');
        //$httpProvider.interceptors.push('LoaderInterceptor');

        $compileProvider.debugInfoEnabled(false);

        $breadcrumbProvider.setOptions({
            prefixStateName: 'dashboard',
            template: 'bootstrap3'
        });

        $httpProvider.interceptors.push(function () {
            return {
                'request': function (config) {
                    // add version to js/src html files
                    if (Array.isArray(config.url.match(/\/js\/src.*.html/g))) {
                        config.url += ( (config.url.indexOf('?') > -1) ? '&' : '?' )
                            + config.paramSerializer({v: __version_number});
                    }

                    return config;
                }
              }
          });
    }]);


App.run(["$rootScope", "$state", "amMoment", "AuthService", 'localStorageService', "LOGGED_USER", "USER_ROLES", 'PopupsService',
    function ($rootScope, $state, amMoment, AuthService, localStorageService, LOGGED_USER, USER_ROLES, PopupsService) {

		$rootScope.email = 'kontakt.bds@ichp.lukasiewicz.gov.pl';

		$rootScope.patterns = {
        	email: /^(?![\.\-_])(([_a-zA-Z0-9-]{1,}\.)*[_a-zA-Z0-9-]{2,}(\.[_a-zA-Z0-9-]{1,})*)@(([a-zA-Z0-9-]{1,}\.)*[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{1,})*)(\.[a-zA-Z]{2,10})$/,
            firstNameSurname: /^[a-ząćęłńóśżźĄĆĘŁŃÓŚŻŹA-Z\s\-'"áäčéëöőüž]*$/,
            street: /(?=^[a-ząćęłńóśżźĄĆĘŁŃÓŚŻŹ0-9A-Z\-'".,\(\)áäčéëöőüž\s])(?=.*[a-zA-Z]).+$/,
            city: /(?=^[a-ząćęłńóśżźĄĆĘŁŃÓŚŻŹ0-9A-Z\-'".,\(\)áäčéëöőüž\s])(?=.*[a-zA-Z]).+$/,
            homeNumber: /(?=^[a-ząćęłńóśżźĄĆĘŁŃÓŚŻŹA-Z0-9\/])(?=.*[0-9]).+$/
        };

		$rootScope.logged = false;
		$rootScope.loaded = false;
		if (!LOGGED_USER) {
			$rootScope.appRoot = appRoot;

			$rootScope.$on('$stateChangeStart', function (event, next) {
	            if (next.data && next.data.authorizedRoles) {
	                window.location.href = '/';
	            } else {
	            	$rootScope.loaded = true;
	            }
	        });

			return;
		} else {
			$rootScope.logged = true;
		}

        $rootScope.loggedUser = LOGGED_USER;
        $rootScope.userRoles = USER_ROLES;

        var routeExceprions = [
            'logout', 'login', 'disclaimers', 'passwordExpired'
        ];

        $rootScope.logo = window.location.protocol + '//' + window.location.host + '/images/logo_bds.png';

        $rootScope.passwordExpired = AuthService.checkPasswordExpiration();
        $rootScope.disclaimers = AuthService.disclaimers();

        checkDateUpdateProfile();

        $rootScope.$state = $state; // state to be accessed from view

        $rootScope.firstLogin = localStorageService.get('firstLogin');
        if ($rootScope.firstLogin === null) {
        	$rootScope.firstLogin = $rootScope.disclaimers;
        	localStorageService.set('firstLogin', $rootScope.firstLogin);
        }

        amMoment.changeLocale('pl');


        //summernote
        $rootScope.summerNoteOptions = {
            height: 200,
            lang: 'pl-PL',
            toolbar: [
                ['edit', ['undo', 'redo']],
                ['headline', ['style']],
                ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
                ['fontface', ['fontname']],
                ['textsize', ['fontsize']],
                ['fontclr', ['color']],
                ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
                ['height', ['height']],
                ['table', ['table']],
                ['insert', ['link', 'hr']],
                //['insert', ['link', 'picture', 'video', 'hr']],
                ['view', ['fullscreen', 'codeview']],
                ['help', ['help']]
            ]
        };



        $rootScope.$on('$stateChangeStart', function (event, next) {

        	if (next.name == 'login') {
        		window.location.href = '/main/#/dashboard';
				return $state.go('dashboard');
			}

            if (routeExceprions.indexOf(next.name) !== -1) {
                return true;
            }

            //przekierowanie przy koniecznosci zmiany hasla
            if ($rootScope.passwordExpired == true) {
                event.preventDefault();
                return $state.go('passwordExpired');
            }


            //przekierowanie disclaimerow
            if ($rootScope.disclaimers == true) {
                event.preventDefault();
                return $state.go('disclaimers');
            }

            if (next.data == undefined || next.data.authorizedRoles == undefined) {
                console.warn("Uzupełnij routing", next);
                return
            }


            var authorizedRoles = next.data.authorizedRoles;

            if (!AuthService.isAuthorized(authorizedRoles)) {
                event.preventDefault();
                if (AuthService.isAuthenticated()) {
                    // user is not allowed
                    return $state.go('dashboard');
                } else {
                    window.location.href = '/';
                }
            }

            PopupsService.setScope($rootScope);

            if (next.name != 'messages') {
                PopupsService.showNewMsg();
            }

            PopupsService.showIsInactiveUserMsg();
        });

        $rootScope.$on('$stateChangeError', function (event, next) {
            console.warn("stateChangeError", event, next);
        });

        $rootScope.loggedUser = LOGGED_USER;
        $rootScope.userRoles = USER_ROLES;

        $rootScope.dateFormat = 'dd/MM/yyyy';
        $rootScope.appRoot = appRoot;


        function checkDateUpdateProfile() {

            if (LOGGED_USER.role.name == USER_ROLES.su) {
                return $rootScope.updateProfile = false;
            }

            var test = true;
            var now = new Date();
            var updateDate = new Date(LOGGED_USER.company.lastProfile);

            if (now.getFullYear() == updateDate.getFullYear()) {
                test = false;
            }

            $rootScope.updateProfile = test;
        }

    }]);

var roles = angular.module("roles", []).constant('USER_ROLES', {
    su: 'SU',
    admin: 'ADMIN',
    user: 'USER',
});
var suTypes = angular.module("suTypes", []).constant('SU_TYPES', {
    su: 'Admin Wewnętrzny',
    su_app: 'Admin Aplikacji',
    su_it: 'Admin IT'
});
App.constant('USER_STATUS', {
    active: 1,
    inactive: 1,
    waiting: 2,
    notConfirmed: 3,
    rejected: 4,
    newAdminWaiting: 5,
    closed: 6,
    newAdminAccepted: 7,
    newAdminRejected: 8
});
App.constant('VERSION', {
    v1: 'v1',
    v2: 'v2-2017',
    getByYear: function(year) {
        if (year > 2017) {
            return this.v2;
        }

        return this.v1;
    },
    getCurrent: function() {
        this.getByYear(new Date().getFullYear());
    }
});
App.constant('VERSIONS', [
    { code: 'v1', name: 'do 2016' },
    { code: 'v2-2017', name: 'od 2017' },
]);