users.controller('UserController', ['$scope', 'userId', 'usersResource', 'userEntity','toaster', 'remindResource','$state',
    function ($scope, userId, usersResource, userEntity, toaster, remindResource, $state) {

        $scope.disabledForm = false;
        $scope.saving = false;

        if ($scope.loggedUser.role.name == $scope.userRoles.su) {
            $scope.disabledForm = true;
        }

        $scope.type = 'new';

        if (userEntity != null) {
            $scope.type = 'edit';
            $scope.data = userEntity.data;
        }


        $scope.message = null;

        //$scope.sendNewPassword = function () {
        //    messagesResource.restorePassword({to: $scope.data.email}, function(response){
        //        if (response.success == true) {
        //            toaster.pop('success', "Wiadomość wysłana");
        //        }
        //    });
        //};


        $scope.sendNewPassword = function () {
            remindResource.save({email: $scope.data.email}, function(response){
                if (response.success == true) {
                    toaster.pop('success', "Wiadomość wysłana");
                }
            });
        };


        $scope.save = function() {

            $scope.saving = true;

            if ($scope.data.id == undefined) {

                return usersResource.save($scope.data, function(response){
                	$scope.saving = false;
                    if (response.success == true) {
                        toaster.pop('success', "Użytkownik został dodany");
                        $state.go('users.list')
                    } else {
                        toaster.pop('error', response.error);
                    }
                });
            }

            usersResource.update({id: $scope.data.id}, $scope.data, function(response){
            	$scope.saving = false;
                if (response.success == true) {
                    toaster.pop('success', "Użytkownik został zaktualizowany");
                    $state.go('users.list')
                } else {
                    toaster.pop('error', response.error);
                }
            });
        };

        $scope.closeForm = function () {
            $state.go('users.list');
        };

    }]);