users.service('reportsGridService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'LOGGED_USER', 'USER_ROLES','reportsResource','companyResource','toaster',
    function ($http, uiGridConstants, moment, gridService, LOGGED_USER, USER_ROLES, reportsResource, companyResource, toaster) {
        var self = this;

        self.resource = null;

        self.gridApi = null;

        self.scope = null;

        self.resourceParams = {};

        var dateRangePickerOptions = {
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Wybierz",
                "cancelLabel": "Anuluj",
                "fromLabel": "Od",
                "toLabel": "Do",
                "customRangeLabel": "Zdefiniuj"
            },
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
                'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
                'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
                'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        var gridColumns = [
            {
                name: "Rok",
                width: '100',
                field: "reportYear",
                fieldType: 'text'
            },
            {
                name: "Podmiot",
                width: '*',
                minWidth: 200,
                field: "companyName",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents"><a href="#/reports/company/{{ row.entity.companyId }}" >{{ row.entity.companyName }}</a></div>'
            },
            {
                name: "Nip",
                width: '*',
                minWidth: 200,
                field: "companies.nip",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.companyNip }}</div>'
            },
            {
                name: 'E-mail',
                width: '15%',
                minWidth: 200,
                field: "email",
                fieldType: 'text'
            },
            {
                name: 'Nazwa',
                width: '15%',
                field: "reports.name",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.name }}</div>'
            },
            {
                name: 'Data utworzenia',
                width: '*',
                field: 'reports.created_at',
                fieldType: 'dateRange',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.created_at | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Data aktualizacji',
                width: '*',
                field: 'reports.updated_at',
                fieldType: 'dateRange',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.updated_at | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Data przekazania',
                width: '*',
                visible: false,
                field: 'reports.submitDate',
                fieldType: 'dateRange',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.submitDate | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Data zatwierdzenia',
                field: 'reports.confirmDate',
                visible: false,
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.confirmDate | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Data zerowego',
                field: 'reports.zeroDate',
                visible: false,
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.zeroDate | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Data błędnego',
                width: '*',
                field: 'reports.incorrectDate',
                visible: false,
                fieldType: 'dateRange',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.incorrectDate | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Data odrzucenia',
                width: '*',
                field: 'reports.rejectDate',
                visible: false,
                fieldType: 'dateRange',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.rejectDate | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Data korekty',
                width: '*',
                field: 'reports.correctionDate',
                visible: false,
                fieldType: 'dateRange',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.correctionDate | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'W spr. korekty',
                width: '*',
                field: 'cm.createdAt',
                fieldType: 'dateRange',
                visible: false,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.correctionMessage | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: "Status",
                enableFiltering: true,
                enableSorting: false,
                enableColumnMenu: false,
                fieldType: 'text',
                field: 'reports.status',
                cellTemplate: function () {
                    return '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 1"><div class="label label-default">Szkic</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 2"><div class="label label-primary">Wysłane</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 3"><div class="label label-success">Zatwierdzone</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 4"><div class="label label-danger">Odrzucone</div></div>'+
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 5"><div class="label label-danger">Błędne</div></div>'+
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 6"><div class="label label-info">Zerowe</div></div>'+
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 7"><div class="label label-info">Błędnie wysłane</div></div>'
                },
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
                '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    options: [{id: 1, label: 'Szkic'}, {id: 2, label: 'Wysłane'}, {id: 3, label: 'Zatwierdzone'}, {id: 4, label: 'Odrzucone'}
                             ,{id: 5, label: 'Błędne'}, {id: 6, label: 'Zerowe'}, {id: 7, label: 'Błędnie wysłane'}]
                }
            },
            {
                name: "Podgląd",
                fieldType: 'text',
                width: '9%',
                //minWidth: 150,
                enableFiltering: false,
                enableSorting: false,
                enableColumnMenu: false,
                cellTemplate: '<div class="ui-grid-cell-contents">' +
                '<a href="#/reports/preview/{{ row.entity.id }}" class="btn btn-default btn-xs"><i class="fa fa-search"></i> <span class="hidden-sm hidden-xs">Podgląd</span></a>'
            }

        ];

        var columnForSU = {
            name: "Edycja",
            fieldType: 'text',
            width: '12%',
            //minWidth: 150,
            enableFiltering: false,
            enableSorting: false,
            enableColumnMenu: false,
            cellTemplate: '<div class="ui-grid-cell-contents">' +
            '<button data-ng-show="row.entity.status === 2 || row.entity.status === 4 || row.entity.status === 5" class="btn btn-default btn-xs" data-ng-click="grid.appScope.openAcceptModal(row.entity)"><i class="fa fa-check"></i> <span class="hidden-sm hidden-xs">Zatwierdź</span></button>'+
            '<button data-ng-show="row.entity.status === 2 || row.entity.status === 3" class="btn btn-danger btn-xs" data-ng-click="grid.appScope.openIncorrectModal(row.entity)"><i class="fa fa-close"></i> <span class="hidden-sm hidden-xs">Błędne</span></button>'+
            '</div>'
        };

        var columnForNotSU = {
            name: "Edycja",
            fieldType: 'text',
            width: '12%',
            minWidth: 150,
            enableFiltering: false,
            enableSorting: false,
            enableColumnMenu: false,
            cellTemplate: '<div class="ui-grid-cell-contents">' +
            '<a href data-ui-sref="reports.edit({id: row.entity.id})" data-ng-disabled="row.entity.status != 1 " class="btn btn-primary btn-xs"><i class="fa fa-edit"></i> <span class="hidden-sm hidden-xs">Edycja</span></a>'+
            '<button data-ng-disabled="row.entity.status != 1 " class="btn btn-warning btn-xs" data-ng-click="grid.appScope.openDeleteModal(row.entity.id)"><i class="fa fa-trash"></i> <span class="hidden-sm hidden-xs">Usuń</span></button>'+
            '<button class="btn btn-primary btn-xs" ng-click="grid.appScope.copyReport(row.entity.id)"><i class="fa fa-copy"></i> <span class="hidden-sm hidden-xs">Kopia</span></button>'+
            '</div>'
        };



        self.getGridOptions = function () {
            gridService.query.type = 0;
            gridService.resource = self.resource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = self.getGridColumns();
            return gridOptions;
        };

        self.getGridColumns = function () {

            if (LOGGED_USER.role.name != USER_ROLES.su) {
                gridColumns = gridColumns.filter(function (item) {
                    return item.field != 'reports.submitDate' &&
                           item.field != 'reports.confirmDate' &&
                           item.field != 'reports.zeroDate' &&
                           item.field != 'reports.incorrectDate' &&
                           item.field != 'reports.rejectDate' &&
                           item.field != 'reports.correctionDate' &&
                           item.field != 'companyName' &&
                           item.field != 'companies.nip' &&
                           item.field != 'email';
                });
            }

            if (LOGGED_USER.role.name != USER_ROLES.su) {
                gridColumns.push(columnForNotSU);
            } else {
                gridColumns.push(columnForSU);
            }

            return gridColumns;

        };

        self.getDateRangePickerOptions = function () {
            return dateRangePickerOptions;
        };

        self.getPage = function () {

            gridService.query.sort = {field:"reports.created_at", direction:"desc"};

            return gridService.getPage();
        };

    }]);