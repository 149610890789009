reports.service('reportsService', ['chemicalsResource', 'reportsResource', 'copyResource', 'productTypesResource', 'toaster', 'countriesResource', 'substanceResource', 'NgTableParams',
    function (chemicalsResource, reportsResource, copyResource, productTypesResource, toaster, countriesResource, substanceResource, NgTableParams) {

        var vm = this;

        vm.page = 1;
        vm.count = 10;
        vm.defaultSorting = {'reports.created_at': 'desc'};
        vm.filter = {};

        var service = {
            getChemicals: getChemicals,
            getProductTypes: getProductTypes,
            saveReport: saveReport,
            sendReport: sendReport,
            getCountries: getCountries,
            deleteSubstance: deleteSubstance,
            deleteReport: deleteReport,
            copyReport: copyReport,
            getNgTableParams: getNgTableParams,
            getNgTableParamsByCompany: getNgTableParamsByCompany,
            checkSZWOFGC: checkSZWOFGC,
            getYears: getYears,
            checkCompanyProfile: checkCompanyProfile,
            serviceInstalation: serviceInstalation,
            inneProcesy: inneProcesy,
            przywozWywoz: przywozWywoz,
            serviceLiquidation: serviceLiquidation,
            checkReportExists: checkReportExists,
            confirmReport: confirmReport,
            markIncorrectReport: markIncorrectReport,
			getProductScopes: getProductScopes,
            verifyReport: verifyReport,
            correctionMessage: correctionMessage,
            checkCorrectionMessage: checkCorrectionMessage,
            preview: preview,
            prevstock: prevstock,
            hasSubmitedReport: hasSubmitedReport,
            hasIncorrectReport: hasIncorrectReport,
            messages: messages,
            markWronglySent: markWronglySent
        };

        return service;


        function getNgTableParams() {
            return new NgTableParams({
                    page: vm.page,
                    count: vm.count,
                    sorting: vm.defaultSorting,
                    filter: vm.filter
                },
                {
                    total: 0, // length of data
                    getData: function ($defer, params) {

                        params.$params.q = params.$params.filter.name;

                        vm.page = params.page();
                        vm.count = params.count();
                        vm.defaultSorting = params.sorting();
                        vm.filter = params.filter();

                        return reportsResource.get(params.url()).$promise.then(function (data) {
                            params.total(data.total);
                            return data.data;
                        });

                    }
                });
        }

        function getNgTableParamsByCompany(id) {
            return new NgTableParams({
                    page: 1,
                    count: 10,
                    sorting: {'reports.created_at': 'desc'}
                },
                {
                    total: 0, // length of data
                    getData: function ($defer, params) {

                        params.$params.q = params.$params.filter.name;


                        // ajax request to api
                        return reportsResource.get(params.url(), {action: 'company', subId: id}).$promise.then(function (data) {
                            params.total(data.total);
                            return data.data;
                        });


                    }
                });
        }


        function getChemicals(searchText, field, type, szwoFgc, year, exceptIds) {
            var query = {};
            query[field] = searchText;

            if (typeof type != 'undefined') {
                query['type'] = type;
            }
            if (typeof szwoFgc != 'undefined') {
                query['SZWO_FGC'] = szwoFgc;
            }
            if (typeof year != 'undefined') {
                query['year'] = year;
            }
            if (typeof exceptIds != 'undefined') {
            	if (!Array.isArray(exceptIds)) {
            		query['exceptIds'] = exceptIds;
            	} else {
                    exceptIds.forEach(function (id, idx) {
                    	query['exceptIds[' + idx + ']'] = id;
                    });
                }
            }

            return chemicalsResource.search(query).$promise.then(getData);
        }

        function getProductTypes(year) {
            return productTypesResource.get({year: year}).$promise.then(getData);
        }

        function saveReport(formData) {
            if (formData.id != undefined) {
                return reportsResource.update(formData).$promise.then(saveNotification);
            }

            return reportsResource.save(formData).$promise.then(saveNotification);
        }

        function verifyReport(formData) {
            return reportsResource.verify(formData).$promise;
        }

        function correctionMessage(data) {
            return reportsResource.correctionMessage(data).$promise;
        }

        function checkCorrectionMessage() {
            return reportsResource.checkCorrectionMessage().$promise;
        }

        function preview(data) {
            return reportsResource.preview(data).$promise;
        }

        function getData(response) {
            if (response.success == true) {
                return response.data;
            } else {
                console.warn("Błąd zapytania", response);
                return false;
            }
        }

        function saveNotification(response) {
            if (response.success == true) {
                toaster.pop('success', 'Sprawozdanie zapisane');

                if (response.errors) {
            		angular.forEach(response.errors, function (error) {
            			response.error.message += '<br>' + (error.szwofgc.chemical.name || error.name || 'Brak substancji');
            		});
            		response.data.error = response.error;
            	}

                return response.data;
            } else {
                toaster.pop('error', response.error);
            }
        }

        function sendNotification(response) {
            if (response.success == true) {
                toaster.pop('success', 'Sprawozdanie przekazane');
                return response.data;
            } else {
                toaster.pop('error', response.error);
                if (response.verification) {
                	return response.verification;
            	}
            }
        }

        function deleteNotification(response) {
            if (response.success == true) {
                toaster.pop('success', 'Szkic sprawozdania usunięty');
                return response.data;
            } else {
                toaster.pop('error', response.error);
            }
        }

        function copyNotification(response) {
            if (response.success == true) {
                toaster.pop('success', 'Sprawozdanie skopiowane');
                return response.data;
            } else {
                toaster.pop('error', response.error);
            }
        }

        function substanceDeleted(response) {
            if (response.success == true) {
                toaster.pop('success', 'Substancja usunięta');
            } else {
                toaster.pop('error', response.error);
            }
        }


        function sendReport(report) {
            report.name = report.name + ' (' + moment().format('DD/MM/YYYY HH:mm')+')';

            if (!report.id) {
                return reportsResource.save(report).$promise.then(sendNotification);
            }
            return reportsResource.update(report).$promise.then(sendNotification);
        }

        function getCountries(type, year) {
            if (type == 'ue') {
                return countriesResource.getUE({ year: year }).$promise.then(getData);
            }

            if (type == 'nonUE') {
                return countriesResource.getNonUE({ year: year }).$promise.then(getData);
            }

            return countriesResource.getAll().$promise.then(getData);
        }


        function deleteSubstance(id) {
            return substanceResource.delete({id: id}).$promise.then(substanceDeleted);
        }

        function deleteReport(id) {
            return reportsResource.delete({id: id}).$promise.then(deleteNotification);
        }

        function copyReport(id) {
            return copyResource.copy({id: id}).$promise.then(copyNotification);
        }


        function getYears() {
            var years = [];
            var currentDate = new Date();
            for (var i = 2015; i <= currentDate.getFullYear() - 1; i++) {
                years.push(i.toString());
            }

            return years;
        }

		function getProductScopes() {
            var scopes = [];
            scopes.push('Zawarte tylko w produktach i urządzeniach');
			scopes.push('Nie zawarte w produktach i urządzeniach');
			scopes.push('Wszystko');

            return scopes;
        }



        function checkSZWOFGC(companyProfile) {
            var test = 0;//domyslnie SZWFO;

            var companyProfileLength = companyProfile.length;

            for (var i = 0; i < companyProfileLength; i++) {
                var entry = companyProfile[i];

                if (entry.dictionary.name.indexOf('FGC') != -1) {
                    test = 1;
                }

                if (entry.dictionary.name.indexOf('SZWO') != -1) {
                    return test = 0;
                }
            }
            return test;
        }

        function checkCompanyProfile(companyProfile, profileName) {
            var test = false;
            var companyProfileLength = companyProfile.length;

            for (var i = 0; i < companyProfileLength; i++) {
                var entry = companyProfile[i];

                if (!Array.isArray(profileName) && entry.dictionary.name.indexOf(profileName) != -1) {
                    return test = true;
                }

                if (Array.isArray(profileName) && profileName.indexOf(entry.dictionary.name) != -1) {
                    return test = true;
                }
            }

            return test;
        }

        function serviceInstalation(sectionName) {
            return ['serwstac',
                    'serwruch',
                    'serwstacklim',
                    'serwruchklim',
                    'serwpomp',
                    'serwpojazd',
                    'serwstacochrony',
                    'serwruchochrony',
                    'serwgasnic',
                    'serwstacrozdzielnic',
                    'serwruchrozdzielnic',
                    'serwrozpusz',
                    'instalstac',
                    'instalruch',
                    'instalstacklim',
                    'instalruchklim',
                    'instalpomp',
                    'instalpojazd',
                    'instalstacochrony',
                    'instalruchochrony',
                    'instalgasnic',
                    'instalstacrozdzielnic',
                    'instalruchrozdzielnic',
                    'instalrozp',
                    'naprawastac',
                    'naprawaruch',
                    'naprawastacklim',
                    'naprawaruchklim',
                    'naprawapomp',
                    'naprawapojazd',
                    'naprawastacochrony',
                    'naprawaruchochrony',
                    'naprawagasnic',
                    'naprawastacrozdzielnic',
                    'naprawaruchrozdzielnic',
                    'naprawarozp',
                    'likwidacjastac',
                    'likwidacjaruch',
                    'likwidacjastacklim',
                    'likwidacjaruchklim',
                    'likwidacjapomp',
                    'likwidacjapojazd',
                    'likwidacjastacochrony',
                    'likwidacjaruchochrony',
                    'likwidacjagasnic',
                    'likwidacjastacrozdzielnic',
                    'likwidacjaruchrozdzielnic',
                    'likwidacjarozp',
                ].indexOf(sectionName) != -1;
        }

        function inneProcesy(sectionName) {
            return ['produkcja', 'zastchem', 'zastlab', 'inne'].indexOf(sectionName) != -1;
        }

        function przywozWywoz(sectionName) {
            return ['przywozzue', 'przywozniezue', 'wywozdoue', 'wywuzniedoue'].indexOf(sectionName) != -1;
        }

        function serviceLiquidation(sectionName)
        {
            return [
                'likwidacjastac',
                'likwidacjaruch',
                'likwidacjastacklim',
                'likwidacjaruchklim',
                'likwidacjapomp',
                'likwidacjapojazd',
                'likwidacjastacochrony',
                'likwidacjaruchochrony',
                'likwidacjagasnic',
                'likwidacjastacrozdzielnic',
                'likwidacjaruchrozdzielnic',
                'likwidacjarozp',
            ].indexOf(sectionName) != -1;
        }

        function checkReportExists(data) {
            data.action = 'check';
            return reportsResource.get(data).$promise.then(getData);
        }

        function confirmReport(report) {
            return reportsResource.save({action: "confirm", id: report.id}).$promise.then(getData);
        }

        function markIncorrectReport(report) {
            return reportsResource.save({action: "incorrect", id: report.id}).$promise.then(getData);
        }

        function markWronglySent(report) {
            return reportsResource.save({action: "wrongly-sent", id: report.id}).$promise.then(getData);
        }

        function getUsages() {
            return [
                {name: 'Serwisowanie', value: 'serw'},
                {name: 'Instalacja', value: 'inst'}
            ]
        }

        function prevstock(data) {
            return reportsResource.prevstock(data).$promise;
        }

        function hasSubmitedReport(id) {
            return reportsResource.hasSubmitedReport({id: id}).$promise;
        }

        function hasIncorrectReport(id) {
            return reportsResource.hasIncorrectReport({id: id}).$promise;
        }

        function messages(id) {
            return reportsResource.messages({id: id}).$promise;
        }
    }]);
