users.controller('AdminChangeListController', ['$scope','$http','adminsChangeService', 'usersResource', function ($scope, $http, adminsChangeService, usersResource) {

    $scope.listName = 'AdminChangeList';

    adminsChangeService.scope = $scope;
    adminsChangeService.resource = usersResource;
    adminsChangeService.resourceParams = {action: 'change'};
    $scope.gridOptions = adminsChangeService.getGridOptions();

    adminsChangeService.getPage();

}]);