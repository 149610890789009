/**
 * Created by jgolebiewski on 18.01.2016.
 */

reports.directive('mixtureCount', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {

            var required = false;
            //console.log("required", ctrl.$errors);


            scope.$watch(attrs.ngRequired, function(nVal, oVal){
                //console.log("watch",nVal, oVal);
                required = nVal ;
                validate();
            });

            scope.$watch(function () {
                return ctrl.$modelValue;
            }, validate);

            function validate() {
                //console.log("requiredV", required);
                if (required == false) {
                    return true;
                }

                var value = ctrl.$modelValue;
                var validate = ctrl.$modelValue == 100;
                //console.log(validate, ctrl.$modelValue, ctrl.$viewValue);
                ctrl.$setValidity('mixCount', validate);

                if (validate == true) {
                    return value;
                }

                return undefined;

            }

        }
    };
});