
users.service('registerService', ['$http', 'uiGridConstants', 'moment', 'gridService', function ($http, uiGridConstants, moment, gridService) {
        var self = this;

        self.resource = null;

        self.gridApi = null;

        self.scope = null;

        self.deviceCategories = [];

        self.resourceParams = {};

        var gridColumns = [
            {
            name: "Operator",
            field: "name",
            fieldType: 'text',
            cellTemplate: '<div class="ui-grid-cell-contents" data-ng-if="row.entity.company_name != null">{{ row.entity.company_name }}</div>' +
            '<div class="ui-grid-cell-contents" data-ng-if="row.entity.company_name == null"></div>'
        },
            {
                name: "NIP",
                field: "nip",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.company_nip }}</div>'
            },
            {
                name: 'Imię i nazwisko',
                field: "surname",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.user_name }} {{ row.entity.user_surname }}</div>'
            },
            {
                name: 'Adres e-mail',
                field: "email",
                minWidth: 200,
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.user_email }}</div>'
            },

            {
                name: 'Data rejestracji',
                width: '*',
                minWidth: 150,
                field: 'companies.registrationDate',
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.registrationDate | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Osoba przypisana',
                field: "r.surname",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.responsible_firstName }} {{ row.entity.responsible_surname }}</div>'
            },
            {
                name: "Status",
                width: '10%',
                //minWidth: 150,
                enableFiltering: true,
                enableSorting: false,
                enableColumnMenu: false,
                fieldType: 'text',
                field: 'users.status',
                cellTemplate: function () {
                    return '' +
                            '<div class="ui-grid-cell-contents" data-ng-if="row.entity.ustatus == 2"><div class="label label-primary label-sm">Oczekuje na aktywację</div></div>' +
                            '<div class="ui-grid-cell-contents" data-ng-if="row.entity.ustatus == 3"><div class="label label-default label-sm">Niepotwierdzony</div></div>' +
                            '<div class="ui-grid-cell-contents" data-ng-if="row.entity.ustatus == 4"><div class="label label-danger label-sm">Odrzucony</div></div>' +
                            '<div class="ui-grid-cell-contents" data-ng-if="row.entity.ustatus == 0"><div class="label label-danger label-sm">Aktywne</div></div>' +
                            '<div class="ui-grid-cell-contents" data-ng-if="row.entity.ustatus == 1"><div class="label label-danger label-sm">Nieaktywne</div></div>' +
                            '<div class="ui-grid-cell-contents" data-ng-if="row.entity.ustatus == 5"><div class="label label-danger label-sm">Oczekuje</div></div>'
                },

                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                        '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
                        '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    //term: 0,
                    options: [{id: 2, label: 'Oczekuje na aktywację'}, {id: 3, label: 'Niepotwierdzony'}, {id: 4, label: 'Odrzucony'}]
                }
            },
            {
                name: "Edycja",
                fieldType: 'text',
                width: '100',
                enableFiltering: false,
                enableSorting: false,
                enableColumnMenu: false,
                cellTemplate: '<div class="ui-grid-cell-contents"><div class="text-center" data-ng-if="row.entity.ustatus != 3"><a href="#/users/registers/{{ row.entity.company_id }}" class="btn btn-primary btn-sm">Weryfikuj</a></div>' +
                        '<div class="text-center" data-ng-if="row.entity.ustatus == 3"><a href="#/users/registers/{{ row.entity.company_id }}" class="btn btn-primary btn-sm">Sprawdź</a></div></div>'
            }
        ];


        self.getGridOptions = function () {
            gridService.query.type = 1;
            gridService.resource = self.resource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = self.getGridColumns();
            return gridOptions;
        };

        self.getGridColumns = function () {
            return gridColumns;

        };

        self.getDateRangePickerOptions = function () {
            return dateRangePickerOptions;
        };

        self.getPage = function () {
            return gridService.getPage();
        };

        return self;

    }]);