messages.controller('MessagesTemplatesListController', ['$scope', 'MessagesTemplatesResource', 'MessagesTemplatesService', 'uiGridConstants','$uibModal', 'toaster',
    function ($scope, MessagesTemplatesResource, MessagesTemplatesService, uiGridConstants, $uibModal, toaster) {
        $scope.listName = 'MessagesTemplatesList';
		$scope.AUTOMATIC_CATEGORY_ID = 1;

        MessagesTemplatesService.scope = $scope;
        MessagesTemplatesService.resource = MessagesTemplatesResource;
        $scope.gridOptions = MessagesTemplatesService.getGridOptions();
        
        $scope.itemToDelete = null;

       MessagesTemplatesService.getCategories('').then(function (response) {
            var index = 1;
            var filters = [];
            angular.forEach(response.data, function(item) {
                filters.push({
                    id: item.name,
                    label: item.name
                });
            });

            $scope.gridOptions.columnDefs[index].filter.options = filters;

            MessagesTemplatesService.getPage();
        });

        $scope.delete = function () {
			return MessagesTemplatesResource.delete({ id: $scope.itemToDelete.id }).$promise.then(function (response) {
		        if (response.success == true) {
					$scope.cancel();
					MessagesTemplatesService.getPage();
		            toaster.pop('success', 'Usunięto');
		            return response.data;
		        } else {
	                toaster.pop('error', response.error);
	            }
		    });
        };

		$scope.cancel = function () {
            $scope.itemToDelete = null;
            $scope.modalInstance.dismiss('cancel');
        };

        $scope.showDeleteModal = function (itemToDelete) {
        	if (itemToDelete.categoryId == $scope.AUTOMATIC_CATEGORY_ID) {
        		return;
        	}
            $scope.itemToDelete = itemToDelete;
            $scope.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/common/views/modals/delete.modal.html',
                animation: true,
                backdrop: 'static',
                keyboard: false,
                scope: $scope
            });
        };
    }
]);
