users.service('companyHistoryService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'companyHistoryResource', 'toaster',
    function ($http, uiGridConstants, moment, gridService, companyHistoryResource, toaster) {
        var self = this;

        self.resource = companyHistoryResource;
        self.gridApi = null;
        self.scope = null;
        self.resourceParams = {};

        var dateRangePickerOptions = {
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Wybierz",
                "cancelLabel": "Anuluj",
                "fromLabel": "Od",
                "toLabel": "Do",
                "customRangeLabel": "Zdefiniuj"
            },
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
                'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
                'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
                'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        var gridColumns = [
            {
                name: 'Data zmiany',
                field: "companies_history.created_at",
                fieldType: 'dateRange',
                width: '100',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents r">{{ row.entity.created_at | amDateFormat:"DD/MM/YYYY HH:mm:ss" }}</div>'
            },
            {
                name: 'Kto zmienił',
                field: "surname",
                fieldType: 'text',
                width: '300',
                cellTemplate: '<div class="ui-grid-cell-contents r">{{ row.entity.user_firstName }} {{ row.entity.user_surname }}</div>'
            },
            {
                name: 'Zmiana',
                field: "action",
                width: '200',
                fieldType: 'text',
            },
            {
                name: 'Opis',
                field: "description",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents r">{{ row.entity.description }}<div ng-if="row.entity.adminId" style="float: right;"><span ng-if="row.entity.description">&nbsp;</span><span ng-click="row.entity.show_admin = !row.entity.show_admin" style="cursor: pointer; border: 1px solid #dddddd; padding: 4px 10px;">Dane poprzedniego administratora <span class="caret"></span></span><div ng-if="row.entity.show_admin" style="position: absolute; border: 1px solid #dddddd; padding: 5px 12px; background: #fff; right: 12px;">Imię i nazwisko: {{ row.entity.admin_firstName }} {{ row.entity.admin_surname }}<br>E-mail: {{ row.entity.admin_email }}<br>Telefon: {{ row.entity.admin_phone }}<br>Data rejestracji: {{ row.entity.admin_registrationDate }}</div></div></div>'
            },

        ];


        self.getGridOptions = function () {
            gridService.query.type = 0;
            gridService.resource = self.resource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = self.getGridColumns();

            return gridOptions;
        };

        self.getGridColumns = function () {
            return gridColumns;
        };

        self.getDateRangePickerOptions = function () {
            return dateRangePickerOptions;
        };

        self.getPage = function () {
            return gridService.getPage();
        };

        return self;
    }
]);