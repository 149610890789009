/**
 * Created by jgolebiewski on 03.11.2015.
 */

common.directive('upload', ['$timeout', function ($timeout) {
    return {
        //templateUrl: appRoot + '/common/views/directives/uploadDirective.html',
        template: '<div class="upload-directive row">' +
        '<div class="col-md-12"> ' +
        '<div data=ng-show="uploadSize != null"><p data-ng-bind="uploadSize"></p></div>' +
        '<div data=ng-show="fileTypes != null"><p data-ng-bind="fileTypes"></p></div>' +
        '<div data=ng-show="errorMsg != null"><p class="text-danger" data-ng-bind="errorMsg"></p></div>' +
        '<div class="btn btn-default fileinput-button" data-ng-disabled="limitReached"> ' +
        '<i class="fa fa-plus"></i> <span>Dodaj</span> ' +
        '<input id="fileupload" type="file" name="files[]" data-ng-disabled="limitReached"/> </div> ' +
        '</div> <div class="col-md-12"> ' +
        '<div id="progress" class="progress" style="display: none; margin-top: 20px"> ' +
        '<div class="progress-bar progress-bar-success"></div> </div> ' +
        '<div class="text-danger" data-ng-show="form.documents.$error.required" data-ng-bind="requiredMsg"></div> ' +
        '<div class="files clearfix"> ' +
        '<div data-ng-repeat="file in data.documents" class="well pull-left"> ' +
        '<div class=""><a href="{{ file.url }}" target="_blank">{{ file.name }}</a> <a href data-ng-click="removeFile($index, file.name)"><i class="fa fa-remove"></i></a></div> </div> </div> </div>',
        restrict: 'A',
        require: 'ngModel',
        controller: ['$scope', '$attrs', '$http', function ($scope, $attrs, $http) {

            $timeout(function () {

                var id = '#fileupload';

                var url = $attrs.url == undefined ? '/files/upload' : $attrs.url;
                $scope.fileTypes = $attrs.fileTypes == undefined ? null : 'Dozwolone formaty plików: ' + $attrs.fileTypes;

                $scope.allowRemove = $attrs.allowRemove == undefined ? false : $attrs.allowRemove;
                $scope.uploadSize = 'Maksymalna wielkość pliku: ' + ($attrs.uploadSizeLimit == undefined ? 5 + ' MB' : $attrs.uploadSizeLimit + ' MB');
                $scope.errorMsg = null;
                $scope.requiredMsg =  $attrs.requiredMsg == undefined ? 'Dokument potwierdzający pełnomoctnictwo jest wymagany' : $attrs.requiredMsg;
                //            $('#progress').hide();
                angular.element(id).fileupload({
                    url: url,
                    dataType: 'json',
                    limitMultiFileUploadSize: $scope.uploadSize,
                    change: function (e, data) {
                        var test = true;

                        var error = null;
                        $.each(data.files, function (index, file) {
                            if(!file.type.match('image/jp.*') && !file.type.match('application/pdf')) {
                                error = "Nie dozwolony format pliku";
                                return test = false;
                            }
                            error = null;
                        });

                        $scope.$apply(function(){
                            $scope.errorMsg = error;
                        });
                        return test;
                    },
                    start: function () {
                        angular.element('#progress').show();
                    },
                    error: function (xhr, status, error) {
                        var errorMsg = null;
                        if (error == 'Request Entity Too Large') {
                            errorMsg = "Przekroczono rozmiar pliku";
                        }

                        $scope.$apply(function () {
                            $scope.errorMsg = errorMsg;
                        });
                    },
                    always: function (e, data) {

                        if (data.result != undefined) {
                            $.each(data.result.data.files, function (index, file) {
                                $scope.data.documents.push(file);

                            });

                            $scope.$apply(function () {
                                $scope.ngModel.$modelValue = $scope.data.documents;
                                $scope.validate();
                                $scope.checkLimit();
                            });
                        }


                        angular.element('#progress').hide();
                    },
                    progressall: function (e, data) {
                        //                        $('#progress').show();
                        var progress = parseInt(data.loaded / data.total * 100, 10);
                        angular.element('#progress .progress-bar').css(
                            'width',
                            progress + '%'
                        );
                    }
                });
            }, 200);

            //$scope.removeFile = function (index, deleteUrl) {
            //    $scope.data.documents.splice(index, 1);
            //    $scope.validate();
            //    $scope.checkLimit();
            //};

            $scope.removeFile = function (index, name) {
                $scope.data.documents.splice(index, 1);
                $scope.validate();
                $scope.checkLimit();

                if ($scope.allowRemove == 'true') {
                    $http.get('/files/delete/' + name).then(function(response){
                        console.log("OK");
                    });
                }

            };

        }],
        link: function (scope, element, attr, ngModel) {


            ngModel.$name = attr.name;
            //scope.data.locations = ngModel.$viewValue;
            ngModel.$setViewValue(scope.data.files);
            var required = false,
                self = this,
                limit = null;

            scope.ngModel = ngModel;
            scope.limitReached = false;

            scope.$watch(attr.required, function (value) {
                required = value === null ? false : value;
                scope.validate();
            });

            scope.$watch(attr.limit, function (value) {
                limit = value === null ? null : value;
                scope.checkLimit();
            });

            scope.validate = function () {
                if (required == true) {
                    var req = scope.data.documents.length > 0;
                    ngModel.$setValidity('required', req, self);
                }
            };

            scope.checkLimit = function () {
                if (limit != null) {
                    if (scope.data.documents.length >= limit) {
                        scope.limitReached = true;
                    } else {
                        scope.limitReached = false;
                    }
                }
            };

            scope.$watch(function () {
                return ngModel.$modelValue;
            }, scope.validate);

        }
    };
}]);