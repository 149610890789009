(function () {

    'use strict';


    angular.module("App").controller('DashboardController', DashboardController)

    DashboardController.$inject = ['$scope', '$uibModal', 'profileDictionaryResource', 'companyService', 'toaster', '$rootScope', 'newsService', 'reportsService', 'localStorageService', 'VERSION', 'NoticesResource', 'NoticeUsersResource'];
    function DashboardController($scope, $uibModal, profileDictionaryResource, companyService, toaster, $rootScope, newsService, reportsService, ls, VERSION, NoticesResource, NoticeUsersResource) {
        var self = this;

        $scope.VERSION = VERSION;

        $scope.loaders = {
            news: true,
            docs: true,
            links: true
        };

        getLinks();
        getDocuments();
        getNews();


        function getLinks() {

            var params = {
                limit: 5,
                page: 0,
                status: 1
            };
            $scope.loaders.links = true;
            newsService.getLinks(params).then(function (response) {
                $scope.links = response.data;
                $scope.loaders.links = false;
            });
        }

        function getDocuments() {

            var params = {
                limit: 5,
                page: 0,
                status: 1
            };
            $scope.loaders.docs = true;
            newsService.getDocuments(params).then(function (response) {
                $scope.documents = response.data;
                $scope.loaders.docs = false;
            });
        }

        function getNews() {
            var params = {
                limit: 5,
                page: 0,
                status: 1
            };
            $scope.loaders.news = true;
            newsService.getNews(params).then(function (response) {
                $scope.news = response.data;
                $scope.loaders.news = false;
            });
        }


        $scope.$on('$includeContentLoaded', function (event) {
            $scope.loadingContent = false;
        });

        if ($scope.updateProfile == true) {
            $scope.dataProfile = {items: []};

            companyService.getCompanyProfile($scope.loggedUser.company.id).then(function (data) {
                $scope.dataProfile.items = data;
            });


            $scope.loadingProfile = true;
            profileDictionaryResource.get({}, function (response) {
                $scope.loadingProfile = false;
                if (response.success == true) {
                    $scope.szwo = response.data.slice(0, 16);
                    $scope.fgaz = response.data.slice(16, (response.data.length + 1));
                }
            });

            $scope.open = function () {

                $scope.modalInstance = $uibModal.open({
                    templateUrl: appRoot + '/application/views/profile.modal.html',
                    size: 'od-lg',
                    animation: true,
                    backdrop: 'static',
                    keyboard: false,
                    scope: $scope,
                    resolve: {
                        szwo: function () {
                            return $scope.szwo;
                        },
                        fgaz: function () {
                            return $scope.fgaz;
                        }
                    }
                });
            };


            $scope.open();

            $scope.save = function () {
                if ($scope.dataProfile.items == undefined || $scope.dataProfile.items.length == 0) {
                    return toaster.pop('warning', "Wybierz profil działalności firmy");
                }

                companyService.updateProfile($scope.loggedUser.company.id, $scope.dataProfile.items).then(function(response){
                    if (response.success == true) {
                        toaster.pop('success', 'Profil został uaktualniony');
                        $scope.modalInstance.close();
                        $rootScope.updateProfile = false;
                    } else {
                        toaster.pop('error', response.error);
                    }
                });
            };
        }
        if (!ls.get('correctionWarningClosed') || ls.get('correctionWarningClosed') !== ls.get('sid')) {
            reportsService.checkCorrectionMessage().then(function(response) {
                if (response.correctionMessageData) {
                    $scope.correctionMessageData = response.correctionMessageData;
                    $scope.closeCorrectionWarningModal = function() {
                        ls.set('correctionWarningClosed', ls.get('sid'))
                        $scope.correctionWarningModal.close();
                    }
                    $scope.correctionWarningModal = $uibModal.open({
                        templateUrl: appRoot + '/application/views/modals/report.correction.warning.modal.html',
                        size: 'large',
                        animation: true,
                        backdrop: 'static',
                        keyboard: false,
                        scope: $scope,
                    });
                }
            });
        }

        $scope.loaders = {
            news: false,
            docs: false,
            links: false
        };

        if ($scope.loggedUser.role.name != $scope.userRoles.su) {
			var params = { unread: 1 };
			if ($rootScope.firstLogin) {
				params.type = 0;
			}

			NoticesResource.get(params).$promise.then(function (response) {
		        if (response.success == true) {
		            $scope.notices = response.data;
		    		if ($scope.notices.length) {
		    			$scope.notice = $scope.notices[0];
		    			$scope.openNoticeModal();
		    		}
		        }
		    });
		}

	    $scope.read = function (notice) {
	        NoticeUsersResource.save({ noticeId: notice.id }).$promise.then(function (response) {
	            if (response.success == true) {
	                //toaster.pop('success', '');
	                $scope.noticeModal.dismiss('cancel');
	                $scope.nextNotice();
	            } else {
	                toaster.pop('error', response.error);
	            }
	        });
	    };

	    $scope.openNoticeModal = function () {
	    	$scope.noticeModal = $uibModal.open({
	    		templateUrl: appRoot + '/notices/views/notice.modal.html',
	    		size: 'large',
	    		animation: true,
	    		backdrop: 'static',
	    		keyboard: false,
	    		scope: $scope,
	    	});
	    };

	    $scope.cancelNoticeModal = function () {
	        $scope.noticeModal.dismiss('cancel');
	        $scope.nextNotice();
	    };

	    $scope.nextNotice = function () {
	    	$scope.notices.shift();
	    	if ($scope.notices.length) {
	    		$scope.notice = $scope.notices[0];
	    		$scope.openNoticeModal();
	    	}
	    };
    }

})();