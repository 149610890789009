users.controller('SuController', ['$scope', 'userId', 'usersResource', 'userEntity','toaster', 'remindResource', '$state', 'rolesResource', 'suService', 'SU_TYPES',
    function ($scope, userId, usersResource, userEntity, toaster, remindResource, $state, rolesResource, suService, SU_TYPES) {

        $scope.SU_TYPES = SU_TYPES;
        $scope.saving = false;

        $scope.type = 'new';
        $scope.data = {
            roleId: 1,
            status: 0
        };

        if (userEntity != null) {
            if (!suService.canEdit(userEntity.data)) {
                toaster.pop('error', "Nie masz uprawnień do edycji tego użytkownika");
                redirect();
            }
            $scope.type = 'edit';
            $scope.data = userEntity.data;
        }

        rolesResource.getSuTypes(function(response) {
            $scope.suTypes = response.data;
         }, $scope.loggedUser.suType);

        $scope.message = null;

        $scope.sendNewPassword = function () {
            remindResource.save({email: $scope.data.email}, function(response){
                if (response.success == true) {
                    toaster.pop('success', "Wiadomość wysłana");
                }
            });
        };

        $scope.save = function() {

            $scope.saving = true;

            if ($scope.data.id == undefined) {

                return usersResource.save($scope.data, function(response){
                	$scope.saving = false;
                    if (response.success == true) {
                        toaster.pop('success', "Użytkownik został dodany");
                        redirect();
                    } else {
                        toaster.pop('error', response.error);
                    }
                });
            }

            usersResource.update({id: $scope.data.id}, $scope.data, function(response){
            	$scope.saving = false;
                if (response.success == true) {
                    toaster.pop('success', "Użytkownik został zaktualizowany");
                    redirect();
                } else {
                    toaster.pop('error', response.error);
                }
            });
        };

        $scope.closeForm = function () {
            redirect();
        };

        function redirect() {
            $state.go('users.su');
        }
    }]);