auth.controller('LogoutController', ['$scope','$http','Session', 'localStorageService', function ($scope, $http, Session, localStorageService) {


    $http.get('/logout').then(function (response) {

        if (response.data.success == true) {
            Session.destroy();
            localStorageService.clearAll();
            window.location.href = '/';
        }

        return false;
    });

}]);