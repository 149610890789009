/**
 * Created by jgolebiewski on 07.12.2015.
 */
reports.controller('CompanyReportsListController', ['$scope', '$stateParams', 'reportsService', '$q','companyEntity', 'companyReportsGridService', 'reportsResource', function ($scope, $stateParams, reportsService, $q, companyEntity, companyReportsGridService, reportsResource) {

    $scope.listName = 'CompanyReportsList';

    $scope.status = status;
    $scope.company = companyEntity.data;

    $scope.idToDelete = null;

    companyReportsGridService.scope = $scope;
    companyReportsGridService.resource = reportsResource;
    companyReportsGridService.resourceParams = {action: 'company', subId: $stateParams.id};
    $scope.gridOptions = companyReportsGridService.getGridOptions();

    companyReportsGridService.getPage();
}]);