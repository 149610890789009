users.service('adminsChangeService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'LOGGED_USER', 'USER_ROLES',
    function ($http, uiGridConstants, moment, gridService, LOGGED_USER, USER_ROLES) {
        var self = this;

        self.resource = null;

        self.gridApi = null;

        self.scope = null;

        self.resourceParams = {};

        var dateRangePickerOptions = {
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Wybierz",
                "cancelLabel": "Anuluj",
                "fromLabel": "Od",
                "toLabel": "Do",
                "customRangeLabel": "Zdefiniuj"
            },
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
                'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
                'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
                'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        var gridColumns = [
            {
                name: "Operator",
                width: '*',
                minWidth: 200,
                field: "name",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents" data-ng-if="row.entity.company.firstName == null"><is-test ng-show="row.entity.company.isTest"></is-test><a href="#/users/company/{{ row.entity.company.id }}" >{{ row.entity.company.name }}</a></div>' +
                '<div class="ui-grid-cell-contents" data-ng-if="row.entity.company.firstName != null"><is-test ng-show="row.entity.company.isTest"></is-test><a href="#/users/company/{{ row.entity.company.id }}" >{{ row.entity.company.firstName }} {{ row.entity.company.surname }}</a></div>'
            },
            {
                name: 'Imię i nazwisko',
                width: '*',
                field: "surname",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.firstName }} {{ row.entity.surname }}</div>'
            },
            {
                name: 'E-mail',
                width: '*',
                minWidth: 200,
                field: "email",
                fieldType: 'text',
            },
            {
                name: 'Telefon',
                width: '*',
                field: "phone",
                fieldType: 'text',
            },
            {
                name: 'Data zgłoszenia',
                field: 'adminChangeDate',
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents ">{{ row.entity.registrationDate | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: "Status",
                width: '10%',
                //minWidth: 150,
                enableFiltering: true,
                enableSorting: false,
                enableColumnMenu: false,
                fieldType: 'text',
                field: 'users.status',
                cellTemplate: function () {
                    return '<div class="ui-grid-cell-contents" ng-show="row.entity.status == 5"><div class="label label-default">Oczekuje</div></div>' + 
                    '<div class="ui-grid-cell-contents" ng-show="row.entity.status == 7"><div class="label label-primary">Niepotwierdzone</div></div>' + 
                    '<div class="ui-grid-cell-contents" ng-show="row.entity.status == 8"><div class="label label-danger">Odrzucone</div></div>'
                },
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
                '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    //term: 0,
                    options: [
                        {id: 5, label: 'Oczekuje'},
                        {id: 7, label: 'Niepotwierdzony'},
                        {id: 8, label: 'Odrzucone'}
                    ]
                }
            },
            {
                name: "Edycja",
                fieldType: 'text',
                width: '12%',
                //minWidth: 150,
                enableFiltering: false,
                enableSorting: false,
                enableColumnMenu: false,
                cellTemplate: '<div class="ui-grid-cell-contents"><div class="text-center"><a href="#/users/adminChange/{{ row.entity.id }}" class="btn btn-primary btn-sm">Weryfikuj</a></div></div>'
            }
        ];


        self.getGridOptions = function () {
            gridService.query.type = 0;
            gridService.resource = self.resource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = self.getGridColumns();
            return gridOptions;
        };

        self.getGridColumns = function () {
            if (LOGGED_USER.role.name != USER_ROLES.su && gridColumns[0].name == 'Podmiot') {
                gridColumns.splice(0,1);
            }
            return gridColumns;

        };

        self.getDateRangePickerOptions = function () {
            return dateRangePickerOptions;
        };

        self.getPage = function () {
            return gridService.getPage();
        };

        return self;

    }]);