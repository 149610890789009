/**
 * Created by jgolebiewski on 29.12.2015.
 */
App.directive('uiSelectRequired', function () {
    return {
        require: 'ngModel', link: function (scope, elm, attrs, ctrl) {

            var requiredDepency = true;

            if (attrs.ngRequired != undefined) {

                scope.$watch(attrs.ngRequired, function (nVal, oVal) {

                    requiredDepency = nVal;
                    //console.log(nVal, oVal, requiredDepency);
                    ctrl.$setValidity('uiSelectRequired', requiredDepency);
                });

            }

            ctrl.$validators.uiSelectRequired = function (modelValue, viewValue) {

                //if (requiredDepency == true && attrs.ngRequired != undefined) {
                //    return true;
                //}

                //console.log(attrs.name, requiredDepency);
                if (requiredDepency == false) {
                    //console.log("tu");
                    return true;
                }
                //if (attrs.ngRequired != undefined && attrs.ngRequired == false) {
                //    return true;
                //}

                var determineVal;
                if (angular.isArray(modelValue)) {
                    determineVal = modelValue;
                } else if (angular.isArray(viewValue)) {
                    determineVal = viewValue;
                } else {
                    return false;
                }

                return determineVal.length > 0;
            };


        }
    };
});