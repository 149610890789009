notifications.controller('LoginHistoryListController', ['$scope', 'loginHistoryResource', 'logsGridService', 'historyService', function ($scope, loginHistoryResource, logsGridService, historyService) {
    var self = this;

    logsGridService.scope = $scope;
    logsGridService.resource = loginHistoryResource;
    $scope.gridOptions = logsGridService.getGridOptions();

    var actionTypes = [];
    var index = 3;

    historyService.getActions().then(function(data){
        angular.forEach(data, function(item) {
            actionTypes.push({
                id: item.id,
                label: item.title
            });
        });
    });
    $scope.gridOptions.columnDefs[index].filter.options = actionTypes;

    logsGridService.getPage();
}]);

