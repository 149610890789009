/**
 * Created by jgolebiewski on 3.11.2015.
 */
register.factory('registerResource', ['$resource', function ($resource) {
    return $resource('/register/:action/:id', {action: '@action', id: '@id'}, {
        save: {
            method: 'POST',
        },
        update: {
            method: 'PUT',
        },
        show: {
            params: {
                action: 'show'
            }
        },
        get: {
            params: {
                action: 'list'
            }
        },
        /*
        changeAdmin: {
            method: 'POST',
            params: {
                action: 'users/saveNewAdmin'
            }
        }
        */
    });
}]);

register.factory('adminResource', ['$resource', function ($resource) {
    return $resource('/users/:action', {action: '@action'}, {
        save: {
            method: 'POST',
            params: {
                action: 'register'
            }
        },
        changeAdmin: {
            method: 'POST',
            params: {
                action: 'saveNewAdmin'
            }
        }
    });
}]);